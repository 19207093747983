import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  vignetteWrapper: ({ erreur }) => ({
    padding: 20,
    alignSelf: 'center',
    '& > div': {
      display: 'flex',
      gap: 5,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 10,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,

      backgroundColor: erreur
        ? theme.palette.red.superLight
        : theme.palette.blue.light,
    },

    '& svg': {
      color: erreur ? theme.palette.red.main : theme.palette.white.main,
    },
    '& p': {
      color: erreur ? theme.palette.red.main : theme.palette.white.main,
    },
  }),
}));

export default useStyles;
