import React from "react";

import NextQuittance from "./NextQuittance";
import _get from "lodash/get";
import PreviewPremium from "./PreviewPremium";
import { renderCellExpand } from "@/components/DataGrid/CellExpand";

const getTotal = (premiums) => {
  if (premiums)
    return premiums.reduce((total, premium) => {
      const sens = premium.sens === "C" ? -1 : 1;
      return (total * 100 + sens * premium.montant * 100) / 100;
    }, 0);
  else return null;
};

const getColumns = (onChangePaymentMode) => [
  {
    field: "numeroImmeuble",
    headerName: "N° Immeuble",
    flex: 0.5,
  },
  {
    field: "buildingAddress",
    headerName: "Adresse Immeuble",
    flex: 1.5,
    renderCell: (params) =>
      renderCellExpand(params, `${_get(params.row, "policyKey") || ""}`),
  },
  {
    field: "nextQuittance",
    headerName: "Quittance",
    filterable: false,
    flex: 1,
    sortComparator: (v1, v2, param1, param2) => {
      return (
        getTotal(param1.api.getRow(param1.id).premiums) -
        getTotal(param2.api.getRow(param2.id).premiums)
      );
    },
    renderCell: (params) => <NextQuittance row={params.row} />,
  },
  { field: "policyNumber", headerName: "Numéro de police", flex: 1 },
  {
    field: "preview",
    headerName: "Visualiser",
    filterable: false,
    sortable: false,
    flex: 0.5,
    renderCell: (params) => {
      if (_get(params.row, "premiums", null) && params.row.premiums.length > 0)
        return <PreviewPremium row={params.row} />;
    },
  },
];

export default getColumns;
