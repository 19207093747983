import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Button, OutlinedInput } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import theme from "@/theme";

interface UploadProps {
  label: string;
  required?: boolean;
  valeur?: File | null;
  onChange: (file: File) => void;
  supprimerFichier: () => void;
  disabled?: boolean;
}

export const Upload = ({
  label,
  valeur,
  onChange,
  supprimerFichier,
  required = true,
  disabled = false,
}: UploadProps) => {
  const { t } = useTranslation();

  const maxSize = 1 * 1024 * 1024; // 1 Mo en octets

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const fichier = event?.target?.files ? event?.target?.files[0] : null;
    if (fichier && fichier.size > maxSize)
      alert("Le fichier ne doit pas dépasser 1 Mo.");
    if (fichier && fichier.size <= maxSize) onChange(fichier);
  };

  const pjChargee = valeur && valeur.name;

  if (pjChargee)
    return (
      <FormControl variant="outlined" disabled={disabled}>
        <InputLabel htmlFor="display-name">
          {t("pno.justificatifIdentite")}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          label={t("pno.justificatifIdentite")}
          value={valeur.name}
          required={required}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Supprimer le fichier"
                edge="end"
                color={"secondary"}
                onClick={supprimerFichier}
                disabled={disabled}
              >
                <DeleteIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    );

  return (
    <div>
      <label htmlFor="upload-pj">
        <Button
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            border: "dotted",
            color: theme.palette.secondary.main,
            zIndex: 1,
            padding: 0,
          }}
          variant="contained"
          color="default"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          <div style={{ lineHeight: "normal" }}>
            {label} {required && "*"}
            <br />
            <span style={{ fontSize: "smaller" }}>(format: PDF, max: 1mo)</span>
          </div>
        </Button>
        <input
          name={"upload"}
          accept="application/pdf"
          id="upload-pj"
          type="file"
          required={required}
          onChange={onUpload}
          style={{
            width: 1,
            height: 1,
            marginLeft: "50%",
          }}
        ></input>
      </label>
    </div>
  );
};
