import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  layout: {
    display: "flex",
    flexDirection: "column",
    width: "65%",
    "& div": {
      marginBottom: 10,
    },
    "& button": {
      marginLeft: "auto",
    },
  },
}));
