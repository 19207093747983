import axiosClient from "@/api/axiosClient";
import { Souscription } from "@/types/Souscription";

const API_BASE_URL = "/v3/souscription";

export interface SouscriptionApiType {
  id: number;
  uuid: string;
  numDevis: string;
  dateCreation: Date;
  dateDerniereModification: Date;
  souscription: Souscription;
}

interface ParcoursUuidType {
  parcoursUuid: string;
}

const souscriptionApi = {
  async persisteInformationsSouscriptionPNO(
    parcoursJson: Partial<Souscription>,
    idParcours: string | null
  ) {
    let url;
    if (idParcours) url = `${API_BASE_URL}/pno/${idParcours}`;
    else url = `${API_BASE_URL}/pno`;

    return await axiosClient.post<ParcoursUuidType>(url, parcoursJson);
  },

  async recupereInformationsSouscriptionPNO(idParcours: string) {
    let url = `${API_BASE_URL}/pno/${idParcours}`;

    return await axiosClient.get<Souscription>(url);
  },

  async recupereSouscriptionsEnCoursPNO() {
    let url = `${API_BASE_URL}/pno/souscriptionsEnCours`;

    return await axiosClient.get<SouscriptionApiType[]>(url);
  },
};

export default souscriptionApi;
