export function formatIBAN(iban) {
  // Supprimer les espaces et les tirets
  iban = iban.replace(/\s+/g, "").replace(/-/g, "");

  // Séparer le code pays et le reste de l'IBAN
  const codePays = iban.substr(0, 2);
  const resteIBAN = iban.substr(2);

  // Ajouter un espace tous les 4 caractères
  let formattedIBAN = resteIBAN.match(/.{1,4}/g).join(" ");

  // Réassembler l'IBAN avec le code pays
  formattedIBAN = codePays + " " + formattedIBAN;

  return formattedIBAN;
}

export function formatSecuriseIBAN(iban) {
  // Supprimer les espaces et les tirets
  iban = iban.replace(/\s+/g, "").replace(/-/g, "");

  // Garder le code pays et remplacer le reste par des X
  const codePays = iban.substr(0, 4);
  const resteIBAN = iban.substr(4, iban.length - 7).replace(/./g, "*");
  const lastThree = iban.slice(-3);

  // Réassembler l'IBAN avec le code pays, les X et les 3 derniers caractères
  const formattedIBAN =
    codePays + " " + resteIBAN.match(/.{1,4}/g).join(" ") + " " + lastThree;

  return formattedIBAN;
}

export function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}
