import React, { useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import { TextSurbrillance } from "@/views/PnoSouscription/Etapes/Recapitulatif";
import { dateFormatStandard } from "@/utils/date";
import { formatNumTel } from "@/utils/phone";

export const InfoClient = () => {
  const { dataSouscription } = useContext(PnoSouscriptionContexte);
  const { t } = useTranslation();
  const data = dataSouscription.data?.contact;

  const nomComplet =
    data?.firstName && data?.lastName
      ? data?.firstName + " " + data?.lastName
      : "-";
  const dateNaissance = data?.birthdate
    ? dateFormatStandard(new Date(data?.birthdate))
    : "-";
  const paysNaissance = data?.countryBirth || "-";
  const villeNaissance = data?.birthCity || "-";
  const numeroTelephone = data?.phone.value
    ? formatNumTel(data?.phone.value)
    : "-";
  const email = data?.mail || "-";

  return (
    <div>
      <Typography>{t("pno.recapitulatif.contact.intro")}</Typography>
      <Typography>
        <Trans
          i18nKey="pno.recapitulatif.contact.info"
          values={{ nomComplet, dateNaissance, villeNaissance, paysNaissance }}
          components={{ style: TextSurbrillance }}
        />
      </Typography>
      <Typography>
        <Trans
          i18nKey="pno.recapitulatif.contact.contact"
          values={{ numeroTelephone, email }}
          components={{ style: TextSurbrillance }}
        />
      </Typography>
    </div>
  );
};
