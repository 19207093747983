import MuiTextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TextFieldProps } from "@material-ui/core/TextField";

import Info from "./Info";

type CustomTextFieldProps = TextFieldProps & {
  info?: string;
};
const TextField = (props: CustomTextFieldProps) => {
  const { info } = props;

  return (
    <MuiTextField
      type="text"
      variant="filled"
      {...props}
      InputProps={{
        endAdornment: info && (
          <InputAdornment position="end">
            <Info title={info} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default TextField;
