type AddresseSuggeree = {
  label: string;
  housenumber: string;
  name: string;
  postcode: string;
  citycode: string;
  city: string;
  street: string;
};

const adresseApi = {
  async getSuggestionAdresses(adresseRecherchee: string) {
    const url = `https://api-adresse.data.gouv.fr/search/?limit=15&q=${adresseRecherchee}`;

    const response = await fetch(url);

    const availableAddressesResponse = await response.json();
    const adresseSuggerees = availableAddressesResponse.features.map(
      (adresse: { properties: AddresseSuggeree[] }) => adresse.properties
    );

    return adresseSuggerees as AddresseSuggeree[];
  },
};

export default adresseApi;
