import axiosClient from "@/api/axiosClient";

const BASE_URI = "/v2/users";

const userApi = {
  getExternalUser: async () => {
    const url = `${BASE_URI}/external`;
    const response = await axiosClient.get(url);
    return response;
  },

  getInternalUser: async (auth) => {
    const url = `${BASE_URI}/internal`;

    const response = await axiosClient.get(url);
    return response;
  },
};

export default userApi;
