const DEPARTEMENT_OUTRE_MER = [
  { zipCode: "971", label: "Martinque" },
  { zipCode: "972", label: "Guadeloupe" },
  { zipCode: "973", label: "Guyane" },
  { zipCode: "974", label: "Réunion" },
  { zipCode: "975", label: "Saint-Pierre-et-Miquelon" },
  { zipCode: "976", label: "Mayotte" },
  { zipCode: "977", label: "Saint-Barthélemy" },
  { zipCode: "978", label: "Saint-Martin" },
  { zipCode: "984", label: "Terres australes et antarctiques françaises" },
  { zipCode: "986", label: "Wallis-et-Futuna" },
  { zipCode: "987", label: "Polynésie française" },
  { zipCode: "988", label: "Nouvelle-Calédonie" },
  { zipCode: "989", label: "Île de Clipperton" },
];

export const estDepartementDOutreMer = (codePostal: string) => {
  return DEPARTEMENT_OUTRE_MER.find((departementDOutreMer) =>
    codePostal?.startsWith(departementDOutreMer.zipCode)
  );
};
