import React from "react";
// import moment from 'moment';
import Switch from "@material-ui/core/Switch";

import NextQuittance from "./NextQuittance";
// import PreviewPremium from './PreviewPremium';
import { ACTIONS_RIGHTS } from "./neededRights";
import _get from "lodash/get";
import { renderCellExpand } from "@/components/DataGrid/CellExpand";
import PreviewPremium from "./PreviewPremium";
import premiumApi from "@/api/premiumApi";

// const formatAnniversaryDate = (date) => {
//   if (date.length === 3) {
//     return `0${date.substring(0, 1)}/${date.substring(1)}`;
//   } else return `${date.substring(0, 2)}/${date.substring(2)}`;
// };

// const getPaymentMethodValue = (paymentMethod) => {
//   if (paymentMethod.toUpperCase().startsWith('VIR')) return true;
//   else if (paymentMethod.toUpperCase().startsWith('PRE')) return false;
//   else return null;
// };

const getTotal = (premiums) => {
  if (premiums)
    return premiums.reduce((total, premium) => {
      const sens = premium.sens === "C" ? -1 : 1;
      return (total * 100 + sens * premium.montant * 100) / 100;
    }, 0);
  else return null;
};

const getObjectForPreview = (row) => {
  const docs = row.premiums.map((premium) => {
    return {
      id: premium.id,
      title: `${premium.paymentMethod.code} ${premium.sens} ${premium.montant}€`,
      titleDownload: `Quittance ${row.numeroImmeuble} - ${premium.paymentMethod.code} ${premium.sens} ${premium.montant}€`,
      apiCall: (auth) => premiumApi.generatePremium(premium.uuid),
    };
  });

  return {
    title: `Quittances de l'immeuble ${row.numeroImmeuble}`,
    docs,
  };
};

const getColumns = (user, onChangePaymentMode) => [
  {
    field: "numeroImmeuble",
    headerName: "N° Imm.",
    flex: 0.6,
  },
  {
    field: "buildingAddress",
    headerName: "Adresse Imm.",
    flex: 1,
    renderCell: (params) =>
      renderCellExpand(params, `${_get(params.row, "policyKey") || ""}`),
  },
  {
    field: "nextQuittance",
    headerName: "Montant",
    flex: 0.8,
    filterable: false,
    sortComparator: (v1, v2, param1, param2) => {
      return (
        getTotal(param1.api.getRow(param1.id).premiums) -
        getTotal(param2.api.getRow(param2.id).premiums)
      );
    },
    renderCell: (params) => <NextQuittance row={params.row} />,
  },
  // {
  //   field: 'policyType',
  //   headerName: 'Type de police',
  //   minWidth: 220,
  //   valueGetter: (params) => `${_get(params.row, 'typePolicy.code') || ''}`,
  // },
  // {
  //   field: 'fonciaOffice',
  //   headerName: 'Agence Foncia',
  //   minWidth: 170,
  //   valueGetter: (params) => `${_get(params.row, 'agence') || ''}`,
  //   hide: true,
  // },
  // {
  //   field: 'buildingCity',
  //   headerName: 'Ville',
  //   minWidth: 170,
  //   valueGetter: (params) => `${_get(params.row, 'ville') || ''}`,
  // },
  // { field: 'annualTtc', headerName: 'TTC annuel', minWidth: 170 },
  // {
  //   field: 'splitting',
  //   headerName: 'Frac.',
  //   minWidth: 100,
  //   valueGetter: (params) =>
  //     `${_get(params.row, 'paymentSplitting.code') || ''}`,
  // },
  // {
  //   field: 'startDate',
  //   headerName: "Date d'effet",
  //   minWidth: 170,
  //   valueGetter: (params) =>
  //     _get(params.row, 'startDate', null)
  //       ? moment(params.row.startDate).format('DD/MM/YYYY')
  //       : '',
  // },
  // {
  //   field: 'contractAnniversaryDate',
  //   headerName: 'Echéance',
  //   minWidth: 130,
  //   renderCell: (params) =>
  //     _get(params.row, 'contractAnniversaryDate', null)
  //       ? formatAnniversaryDate(params.row.contractAnniversaryDate.toString())
  //       : '',
  // },

  {
    field: "paymentMethod",
    headerName: "Prélèvement",
    flex: 0.5,
    filterable: false,
    sortable: false,
    renderCell: (params) => {
      if (_get(params.row, "premiums", null) && params.row.premiums.length > 0)
        return (
          <Switch
            checked={params.row.premiums[0].paymentMethod.code === "PRLV"}
            onChange={() => onChangePaymentMode(params.row)}
            disabled={
              !user ||
              !user.rights ||
              !user.rights.includes(ACTIONS_RIGHTS.updatePaymentType) ||
              params.row.etatPP !== "PLANIFIE"
            }
          />
        );
      else return <></>;
    },
  },
  {
    field: "reasonResult",
    headerName: "Raison refus",
    flex: 0.7,
    filterable: false,
    sortable: false,
    renderCell: (params) =>
      renderCellExpand(params, `${params.row?.premiums[0]?.paymentMethod?.reasonResult || ""}`),
  },
  { field: "policyNumber", headerName: "Numéro de police", flex: 0.8 },
  // {
  //   field: "etatPP",
  //   headerName: "Statut",
  //   flex: 0.5,
  //   valueGetter: (params) => `${_get(params.row, "etatPP") || ""}`,
  // },
  {
    field: 'preview',
    headerName: ' ',
    filterable: false,
    sortable: false,
    flex: 0.3,
    renderCell: (params) => {
      if (
        _get(params.row, 'premiums', null) &&
        params.row.premiums.length > 0 &&
        !!params.row.premiums.find((premium) => {
          return (
            premium.etatPPQ.startsWith('QUITTANCE_P_') ||
            premium.etatPPQ.startsWith('QUITTANCE_AP_')
          );
        })
      )
        return <PreviewPremium object={getObjectForPreview(params.row)} />;
    },
  },
];

export default getColumns;
