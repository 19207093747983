export type OperatorType =
  | 'EQUALS'
  | 'NOT_EQUAL'
  | 'CONTAINS'
  | 'NOT_CONTAINS'
  | 'STARTS_WITH'
  | 'NOT_STARTS_WITH'
  | 'END_WITH'
  | 'NOT_END_WITH'
  | 'LESS_THAN'
  | 'LESS_THAN_EQUAL'
  | 'GREATER_THAN'
  | 'GREATER_THAN_EQUAL'
  | 'IN'
  | 'BETWEEN';

export type OperatorDataType = 'string' | 'number';

export interface Operator {
  value: OperatorType;
  label: string;
  type: OperatorDataType[];
}

export const OPERATORS: Operator[] = [
  { value: 'EQUALS', label: '=', type: ['string', 'number'] },
  { value: 'NOT_EQUAL', label: '!=', type: ['string', 'number'] },
  {
    value: 'CONTAINS',
    label: 'common.dmri.filter.operators.contains',
    type: ['string'],
  },
  {
    value: 'NOT_CONTAINS',
    label: 'common.dmri.filter.operators.notContains',
    type: ['string'],
  },
  {
    value: 'STARTS_WITH',
    label: 'common.dmri.filter.operators.startsWith',
    type: ['string'],
  },
  {
    value: 'NOT_STARTS_WITH',
    label: 'common.dmri.filter.operators.notStartsWith',
    type: ['string'],
  },
  {
    value: 'END_WITH',
    label: 'common.dmri.filter.operators.endsWith',
    type: ['string'],
  },
  {
    value: 'NOT_END_WITH',
    label: 'common.dmri.filter.operators.notEndsWith',
    type: ['string'],
  },
  { value: 'LESS_THAN', label: '<', type: ['number'] },
  { value: 'LESS_THAN_EQUAL', label: '<=', type: ['number'] },
  { value: 'GREATER_THAN', label: '>', type: ['number'] },
  { value: 'GREATER_THAN_EQUAL', label: '>=', type: ['number'] },
  {
    value: 'IN',
    label: 'common.dmri.filter.operators.in',
    type: ['string', 'number'],
  },
  {
    value: 'BETWEEN',
    label: 'common.dmri.filter.operators.between',
    type: ['number'],
  },
];
