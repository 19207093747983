import axiosClient from "./axiosClient";
import { Souscription } from "@/types/Souscription";
import { Personne } from "@/types/personne";

const BASE_URL = "/v3/users";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type Contact = getTypeOriginalDepuisPartial<Souscription["data"]["contact"]>;

const customerApi = {
  async getInfoProprietaireParNum(
    numeroProprietaireFoncia: number
  ): Promise<Partial<Contact>> {
    const url = `${BASE_URL}/proprietaire/${numeroProprietaireFoncia}`;
    const response = await axiosClient.get<Partial<Contact>>(url);

    return response as Partial<Contact>;
  },

  async getInfoProprietaireParMail(
    mailProprietaireFoncia: string
  ): Promise<Partial<Contact>> {
    const url = `${BASE_URL}/proprietaire`;
    const response = await axiosClient.get<Partial<Contact>>(url, {
      params: {
        mail: mailProprietaireFoncia,
      },
    });

    return response as Partial<Contact>;
  },

  async enregistreProprio(proprietaire: Personne, pj: File): Promise<string> {
    const url = `${BASE_URL}/proprietaire`;

    const formData = new FormData();
    formData.append("file", pj);

    // @ts-ignore
    Object.keys(proprietaire).forEach((key: keyof Contact) => {
      if (proprietaire[key] !== undefined) {
        formData.append(key, proprietaire[key] as string);
      }
    });
    const response = await axiosClient.post<Partial<string>>(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const proprietaireUUID = response.data;
    return proprietaireUUID;
  },
};

export default customerApi;
