import React from "react";
import PropTypes from "prop-types";

import PdfViewVer from "@/components/PdvViewver";
import useStyles from "./style";

function TabPanel(props) {
  const { key, tab, index, fileUrl, error } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={tab !== index}
      id={key}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
    >
      {tab === index && <PdfViewVer filePath={fileUrl} error={error} />}
    </div>
  );
}

TabPanel.propTypes = {
  key: PropTypes.string.isRequired,
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  tab: PropTypes.any.isRequired,
  fileUrl: PropTypes.string.isRequired,
  error: PropTypes.bool,
};

export default TabPanel;
