import dashboard from "./dashboard.json";
import common from "./common.json";
import pno from "./pno.json";

const fr = {
  translation: {
    common,
    dashboard,
    pno,
  },
};

export default fr;
