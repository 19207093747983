import { useNavigate } from "react-router-dom";

import souscriptionApi, { SouscriptionApiType } from "@/api/souscriptionApi";
import { formatDate } from "@/utils";
import { ApiTable } from "@/components/ApiTable";

import { typeBien } from "constants/common";
import { URLS } from "@/constants/urls";

const SouscriptionsEnCours = () => {
  const redirect = useNavigate();

  const getTypeBienNom = (tybeBien?: string) => {
    switch (tybeBien) {
      case typeBien.MAISON:
        return "Maison";
      case typeBien.APPARTEMENT:
        return "Appartement";
      case typeBien.MONUMENT_HISTORIQUE:
        return "Maison";
      case typeBien.GARAGE:
        return "Maison";
      case typeBien.LOCAL_COMMERCIAL:
        return "Maison";
      default:
        return "";
    }
  };

  return (
    <ApiTable<SouscriptionApiType>
      rowIdentifier="id"
      getData={() => souscriptionApi.recupereSouscriptionsEnCoursPNO()}
      dataStructure={[
        {
          renderer: (e) =>
            formatDate(e.dateCreation.toString(), "DD/MM/YYYY") || "",
          label: "date",
        },
        {
          renderer: (e) => e.souscription.data.numFoncia || "",
          label: "ref prop",
        },
        {
          renderer: (e) => e.souscription?.data.contact?.lastName || "",
          label: "nom",
        },
        {
          renderer: (e) => e.souscription?.data.contact?.firstName || "",
          label: "prenom",
        },
        {
          renderer: (e) =>
            e.souscription?.data.contractAddress?.lotNumber || "",
          label: "ref lot foncia",
        },
        {
          renderer: (e) => getTypeBienNom(e.souscription?.data.housingType),
          label: "type bien",
        },
        {
          renderer: (e) => e.souscription?.data.contractAddress?.city || "",
          label: "Ville",
        },
        {
          renderer: (e) => e.numDevis,
          label: "Devis",
        },
        {
          renderer: (e) => (
            <button
              onClick={() => redirect(URLS.PNO_SOUSCRIPTION + "/" + e.uuid)}
            />
          ),
          label: "",
        },
      ]}
    />
  );
};

export default SouscriptionsEnCours;
