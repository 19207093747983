import React, { ChangeEvent } from "react";

import {
  FormControlLabel,
  Radio as RadioUI,
  RadioGroup,
} from "@material-ui/core";
import { generateUUID } from "@/utils/uuid";

interface RadioItem {
  value: string;
  label: string;
}

interface RadioProps<T> {
  value?: string;
  items: RadioItem[];
  type?: keyof T;
  onChange: (e: ChangeEvent<HTMLInputElement>, type?: keyof T) => void;
  disabled?: boolean;
}

export const Radio = <T,>({
  value,
  type,
  items,
  onChange,
  disabled = false,
}: RadioProps<T>) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e, type);
  };

  return (
    <RadioGroup row onChange={handleChange} value={value}>
      {items.map((item) => (
        <FormControlLabel
          key={generateUUID()}
          value={item.value}
          control={<RadioUI required />}
          label={item.label}
          disabled={disabled}
        />
      ))}
    </RadioGroup>
  );
};
