import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";

import Popin from "@/components/Popin";
import { downloadFileFromLink } from "@/utils";
import premiumApi from "@/api/premiumApi";
import useAuth from "@/hooks/useAuth";

import TabPanel from "./TabPanel";
import useStyles from "./style";

const PreviewPremium = (props) => {
  const { row } = props;
  const auth = useAuth();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [fileUrl, setFileUrl] = useState(null);
  const [error, setError] = useState(null);
  const classes = useStyles();

  const handleViewMoreClick = () => {
    setOpen(!open);
  };

  const handleClose = () => setOpen(false);

  const handleChange = (event, newTab) => {
    setError(false);
    setTab(newTab);
    generatePremium(newTab);
  };

  const generatePremium = async (currentTab) => {
    try {
      const blob = await premiumApi.generatePremium(
        auth,
        row.premiums[currentTab].uuid
      );

      const url = window.URL.createObjectURL(new Blob([blob]));

      setFileUrl(url);
    } catch (error) {
      setError(true);
      setFileUrl(null);
      // enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleDownload = () => {
    if (fileUrl) {
      const premium = row.premiums[tab];

      downloadFileFromLink(
        fileUrl,
        `Quittance ${row.numeroImmeuble} - ${premium.paymentMethod.code} ${premium.sens} ${premium.montant}€`,
        "pdf"
      );
    }
  };

  useEffect(() => {
    if (open) generatePremium(tab);
  });

  const renderContent = () => {
    return (
      <div className={classes.tabsWrapper}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tab}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          {row.premiums.map((premium) => (
            <Tab
              classes={{ root: classes.tab, selected: classes.selectedTab }}
              key={`tab-${premium.id}`}
              label={`${premium.paymentMethod.code} ${premium.sens} ${premium.montant}€`}
            />
          ))}
        </Tabs>
        {row.premiums.map((premium, index) => (
          <TabPanel
            key={`tabPanel-${premium.id}`}
            tab={tab}
            index={index}
            fileUrl={fileUrl}
            error={error}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <Button onClick={handleViewMoreClick} variant="outlined" color="primary">
        {t("dashboard.preview")}
      </Button>

      <Popin
        title={`Quittances de l'immeuble ${row.numeroImmeuble}`}
        open={open}
        onClose={handleClose}
        content={renderContent()}
        maxWidth="xl"
        showCancelButton={true}
        cancelButtonLabel={t("common.download")}
        onCancel={handleDownload}
        isCancelButtonDisabled={!fileUrl}
      />
    </>
  );
};

PreviewPremium.propTypes = {
  row: PropTypes.shape().isRequired,
  //auth: PropTypes.shape().isRequired,
};

export default PreviewPremium;
