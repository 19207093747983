import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import Loader from "@/components/Loader";

interface BoutonSuivantProps {
  estDesactive?: boolean;
  onClick?: () => Promise<string | null>;
  label?: string;
}
export const BoutonSuivant = ({
  onClick,
  estDesactive = false,
  label,
}: BoutonSuivantProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { formRef } = useContext(PnoSouscriptionContexte);
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const submitForm = () => {
      if (formRef?.current) formRef.current.requestSubmit();
    };

    const fomulaireValide =
      formRef?.current && formRef?.current.checkValidity();

    if (fomulaireValide && onClick) {
      setLoading(true);
      onClick()
        .then(() => submitForm())
        .catch((erreur) => {
          if (erreur !== null) {
            const messageErreur = erreur || "Une erreur inconue est survenue";
            enqueueSnackbar(messageErreur, {
              variant: "error",
            });
          }
        })
        .finally(() => setLoading(false));
    } else submitForm();
  };

  return (
    <>
      {loading && (
        <div>
          <Loader />
        </div>
      )}

      <Button
        type="submit"
        color="primary"
        variant="contained"
        size="large"
        onClick={handleClick}
        style={{ marginLeft: "auto" }}
        disabled={loading || estDesactive}
      >
        {label || t("pno.etapeSuivante")}
      </Button>
    </>
  );
};
