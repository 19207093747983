import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";

import TextField from "@/components/TextField";
import customerApi from "@/api/customerApi";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import { useStyles } from "./style";
import { Souscription } from "@/types/Souscription";
import { useTranslation } from "react-i18next";
import Popin from "@/components/Popin";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type Contact = getTypeOriginalDepuisPartial<Souscription["data"]["contact"]>;
type ContactBooleanMap = { [K in keyof Contact]: boolean };

export const RechercheProrio = ({
  onClose,
  setChampsDesactives,
}: {
  onClose: () => void;
  setChampsDesactives: React.Dispatch<
    React.SetStateAction<{} | ContactBooleanMap>
  >;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { dataSouscription, setDataSouscription } = useContext(
    PnoSouscriptionContexte
  );
  const [numProprioSearch, setNumProprioSearch] = useState<number | null>(null);
  const [emailProprioSearch, setEmailProprioSearch] = useState<string>("");
  const [etatRecherche, setEtatRecherche] = useState<
    "attente" | "proprioNonTrouveParNum" | "erreur"
  >("attente");
  const [loading, setLoading] = useState(false);

  const rechercherProprietaireParNum = async (
    numeroProprietaire: number | null
  ) => {
    if (numeroProprietaire == null) return;
    try {
      setLoading(true);
      const resultatRechercheProprio =
        await customerApi.getInfoProprietaireParNum(numeroProprietaire);

      if (resultatRechercheProprio) {
        onClose();
        setDataSouscription({
          ...dataSouscription,
          data: {
            ...dataSouscription.data,
            numFoncia: numProprioSearch?.toString(),
            contact: buildInfoProprio(resultatRechercheProprio) as Contact,
          },
        });
        setChampsDesactives(buildInfoDesactive(resultatRechercheProprio));
      } else {
        setEtatRecherche("proprioNonTrouveParNum");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // @ts-ignore
      if (error?.response?.status === 404)
        setEtatRecherche("proprioNonTrouveParNum");
      else {
        logErreurRechercheProprio(error);
      }
    }
  };

  const rechercherProprietaireParMail = async (mail: string) => {
    if (mail === "") return;
    try {
      setLoading(true);
      const resultatRechercheProprio =
        await customerApi.getInfoProprietaireParMail(mail);

      if (resultatRechercheProprio) {
        onClose();
        setDataSouscription({
          ...dataSouscription,
          data: {
            ...dataSouscription.data,
            numFoncia: numProprioSearch?.toString(),
            contact: buildInfoProprio(resultatRechercheProprio) as Contact,
          },
        });
        setChampsDesactives(buildInfoDesactive(resultatRechercheProprio));
      } else {
        //setEtatRecherche("erreur");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // @ts-ignore
      if (error?.response?.status === 404) {
        //setEtatRecherche("erreur");

        onClose();
        setDataSouscription({
          ...dataSouscription,
          data: {
            ...dataSouscription.data,
            numFoncia: numProprioSearch?.toString(),
            contact: buildInfoProprio({ mail: emailProprioSearch }) as Contact,
          },
        });
        setChampsDesactives({ mail: true });
      } else {
        logErreurRechercheProprio(error);
      }
    }
  };

  const logErreurRechercheProprio = (error: any) => {
    console.error("Une erreur est survenue: ", error);
    enqueueSnackbar(
      "Une erreur est survenue lors de la récupération du propriétaire Foncia, veuillez réessayer ultérieurement",
      {
        variant: "error",
      }
    );
  };

  useEffect(() => {
    if (numProprioSearch) setEtatRecherche("attente");
  }, [numProprioSearch]);

  useEffect(() => {
    if (emailProprioSearch) setEtatRecherche("proprioNonTrouveParNum");
  }, [emailProprioSearch]);

  useEffect(() => {
    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        numFoncia: "",
        contact: buildInfoProprio({}) as Contact,
      },
    });
    setChampsDesactives(buildInfoDesactive({}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValidate = () => {
    etatRecherche === "attente"
      ? rechercherProprietaireParNum(numProprioSearch)
      : etatRecherche === "proprioNonTrouveParNum"
      ? rechercherProprietaireParMail(emailProprioSearch)
      : console.error("L'etat n'est pas géré");
  };

  return (
    <Popin
      title={t("pno.rechercherProprietaire")}
      open={true}
      validateButtonLabel={"Suivant"}
      onValidate={onValidate}
      onClose={onClose}
      validateLoading={loading}
      isValidateButtonDisabled={etatRecherche === "erreur"}
      useValidateLoader={true}
      maxWidth="sm"
      content={
        <Box className={classes.popinLayout}>
          <Typography variant="h6">{t("pno.jaiNumFoncia")}</Typography>
          <TextField
            label={t("pno.numFoncia")}
            type="number"
            variant="outlined"
            onChange={(e) => setNumProprioSearch(parseInt(e.target.value))}
            inputProps={{ maxLength: 8 }}
            required
          />

          {(etatRecherche === "proprioNonTrouveParNum" ||
            etatRecherche === "erreur") && (
            <>
              <Typography>{t("pno.erreurRechercheProprioParNum")}</Typography>
              <TextField
                label={t("pno.email")}
                type="email"
                variant="outlined"
                onChange={(e) => setEmailProprioSearch(e.target.value)}
                inputProps={{ maxLength: 100 }}
              />
            </>
          )}

          {etatRecherche === "erreur" && (
            <Typography>{t("pno.aucunProprioTrouve")}</Typography>
          )}
        </Box>
      }
    />
  );
};

const buildInfoProprio = (resultatRechercheProprio: Partial<Contact>) => {
  return {
    gender: resultatRechercheProprio.gender || "",
    lastName: resultatRechercheProprio.lastName || "",
    firstName: resultatRechercheProprio.firstName || "",
    mail: resultatRechercheProprio.mail || "",
    phone: { value: resultatRechercheProprio.phone || "" },
    birthdate: resultatRechercheProprio.birthdate || "",
    birthCity: resultatRechercheProprio.birthCity || "",
    countryBirth: resultatRechercheProprio.countryBirth || "",
  };
};

const buildInfoDesactive = (resultatRechercheProprio: Partial<Contact>) => {
  return {
    gender: !!resultatRechercheProprio.gender,
    lastName: !!resultatRechercheProprio.lastName,
    firstName: !!resultatRechercheProprio.firstName,
    mail: !!resultatRechercheProprio.mail,
    phone: { value: !!resultatRechercheProprio.phone },
    birthdate: !!resultatRechercheProprio.birthdate,
    birthCity: !!resultatRechercheProprio.birthCity,
    countryBirth: !!resultatRechercheProprio.countryBirth,
  };
};
