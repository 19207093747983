import React from "react";
import { useTranslation } from "react-i18next";

import Tabs from "@/components/Tabs";
import Details from "./Details";
import Claims from "./Claims";
import Premiums from "./Premiums";

const Contract = () => {
  const { t } = useTranslation();

  return (
    <>
      <Details />
      <Tabs
        tabsList={[
          {
            label: t("common.dmri.premiums"),
            render: () => <Premiums />,
          },
          {
            label: t("common.dmri.claims"),
            render: () => <Claims />,
          },
        ]}
      />
    </>
  );
};

export default Contract;
