import React, { useContext } from "react";
import { Outlet } from "react-router-dom";

import { UserContext } from "@/contexts/user";
import { hasAccess } from "@/utils/auth";
import ForbiddenAccess from "@/views/Errors/ForbiddenAccess";

interface IAuthorizedRouteProps {
  neededRights: string[];
}

const AuthorizedRoute: React.FC<IAuthorizedRouteProps> = (
  props: IAuthorizedRouteProps
) => {
  const [state]: any = useContext(UserContext);
  const userAccessRights = state.rights || [];

  const { neededRights } = props;

  const isAuthorized = hasAccess(neededRights, userAccessRights);

  if (isAuthorized) {
    return <Outlet />;
  } else {
    return <ForbiddenAccess />;
  }
};

export default AuthorizedRoute;
