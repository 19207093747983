import { createTheme } from '@material-ui/core/styles';

const colors = {
  white: {
    main: '#FFFFFF',
  },
  black: {
    main: '#000000',
  },
  orange: {
    main: '#FF9100',
    dark: '#C5710E',
    light: 'rgba(255, 234, 205, 1 )',
  },
  blue: {
    main: '#002B68',
    dark: '#0A2347',
    light: '#0490B2',
    light2: '#E9F0FD',
    light3: '#B3BFD2',
    mixteBg: 'rgb(207, 245, 246)',
    mixte: 'rgb(0, 85, 188)',
    sky: '#e1eeff',
  },
  grey: {
    main: '#B3BFD2',
    light: '#808080',
    superLight: '#E1E1E1',
  },
  greyBlue: {
    main: '#CAD2DE',
  },
  red: {
    main: '#FF4C00',
    pdf: '#ae0d02',
    light: '#f44336',
    superLight: '#FFEDE5',
  },
  yellow: {
    main: '#ffc107',
    light: '#fbee91',
  },
  green: {
    main: '#28a745',
  },
  prussiaBlue: {
    main: '#0192b8',
  },
  lightBlue: {
    main: '#0490B2',
  },
  darkBlue: {
    main: '#0A2347',
  },
  minBlue: {
    main: '#F2F7FF',
  },
};

const palette = {
  primary: {
    main: colors.orange.main,
    dark: colors.orange.dark,
    light: colors.orange.light,
    contrastText: 'rgba(255,255,255,0.87)',
  },
  secondary: {
    main: colors.blue.main,
    light: colors.blue.light,
  },
  text: {
    primary: colors.blue.main,
    secondary: colors.blue.dark,
  },
  background: {
    default: colors.white.main,
  },
  ...colors,
};

const typography = {
  h1: {
    fontFamily: 'Domine',
    fontWeight: 1000,
  },
  h2: {
    fontFamily: 'Domine',
    fontWeight: 1000,
  },
  h3: {
    fontFamily: 'Domine',
    fontWeight: 1000,
    fontSize: '1.7rem',
    color: colors.blue.main,
  },
  h4: {
    fontFamily: 'Domine',
    fontWeight: 1000,
    color: colors.blue.main,
    '@media (max-width:600px)': {
      fontSize: '1.225rem',
    },
  },
  h5: {
    fontFamily: 'Domine',
    fontWeight: 1000,
  },
  h6: {
    fontFamily: 'Domine',
    fontWeight: 700,
    color: colors.blue.main,
  },
  subtitle1: {
    fontFamily: 'Domine',
    letterSpacing: '0em',
    fontWeight: 1000,
    color: colors.blue.main,
  },
  subtitle2: {
    fontFamily: 'Inter',
    fontSize: 17,
    fontWeight: 600,
    letterSpacing: '0em',
  },
  body1: {
    fontFamily: 'Inter',
    letterSpacing: '0em',
    color: colors.blue.main,
    fontSize: '1rem',
    '@media (max-width:600px)': {
      fontSize: '0.85rem',
    },
  },
  body2: {
    fontFamily: 'Inter',
    letterSpacing: '0em',
    color: colors.blue.light,
    fontSize: '1rem',
    '@media (max-width:600px)': {
      fontSize: '0.85rem',
    },
  },
  button: {
    fontFamily: 'Inter',
  },
  caption: {
    fontFamily: 'Inter',
    color: 'rgba(0,0,0,0.4)',
    fontSize: 12,
  },
};
// props: {
//   MuiAppBar: {
//     color: 'transparent'
//   }
// },
const overrides = {
  MuiAppBar: {
    root: {
      boxShadow: 'none',
      paddingLeft: 100,
    },
  },
  MuiToolbar: {
    gutters: {
      paddingLeft: 0,
    },
  },
  MuiCard: {
    root: {
      borderColor: colors.blue.main,
      boxShadow: 'none',
    },
  },
  MuiAlert: {
    root: {
      padding: '0 16px',
    },
  },
  MuiCardContent: {
    root: {
      paddingBottom: '16px !important',
    },
  },
  MuiButton: {
    root: {
      textTransform: 'initial',
    },
    contained: {
      boxShadow: 'none',
    },
  },
  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  MuiInputLabel: {
    shrink: {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  MuiFilledInput: {
    root: {
      border: `1px solid ${colors.grey.main}`,
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      '&.Mui-focused': {
        borderColor: colors.orange.main,
      },
    },
    inputAdornedEnd: {
      paddingRight: 12,
    },
    underline: {
      '&:after': {
        content: 'none',
      },
      '&:before': {
        content: 'none',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      color: `${palette.red.main}! important`,
    },
  },
  MuiOutlinedInput: {
    root: {
      border: 0,
      borderRadius: 10,
      transition: 'border-color 0.3s ease-out',
    },
    notchedOutline: {
      borderColor: colors.grey.main,
    },
    input: {
      padding: '19.5px 14px',
    },
  },
  MuiPopover: {
    root: {
      zIndex: '1400 !important',
    },
  },
  MuiDataGrid: {
    root: {
      '&:focus': {
        outline: 'none !important',
      },
      '&:focus-within': {
        outline: 'none !important',
      },
    },
    cell: {
      '&:.Mui-selected': {
        outline: 'none !important',
      },
      '&:focus-within': {
        outline: 'none !important',
      },
    },
  },

  MuiTooltip: {
    tooltip: {
      fontSize: 14,
      whiteSpace: 'pre-line',
      backgroundColor: colors.orange.light,
      color: palette.secondary.main,
      border: '1px solid transparent',
      minWidth: 200,
      textAlign: 'left',
    },
    arrow: {
      color: colors.orange.light,
    },
  },
};

const theme = createTheme({
  palette,
  typography,
  overrides,
});

export default theme;
