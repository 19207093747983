import React, { useContext, useEffect, useState } from "react";

import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import theme from "@/theme";
import PdfViewVer from "@/components/PdvViewver";
import Popin from "@/components/Popin";
import { downloadFileFromLink } from "@/utils";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import devisApi from "@/api/devisApi";

export const TelechargeDevis: React.FC = () => {
  const { dataSouscription, parcoursUUID } = useContext(
    PnoSouscriptionContexte
  );

  const { t } = useTranslation();
  const [urlPDF, setUrlPDF] = useState<string | null>(null);
  const [erreurGenerationPDF, setErreurGenerationPDF] = useState(false);
  const [afficherPopinDevis, setAfficherPopinDevis] = useState(false);

  const data = dataSouscription.data?.contact;

  useEffect(() => {
    if (afficherPopinDevis && !urlPDF && !erreurGenerationPDF) {
      generarerPdf();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afficherPopinDevis]);

  const generarerPdf = async () => {
    try {
      const blob = await devisApi.genererDevisC2({
        parcoursUUID: parcoursUUID!,
      });
      setUrlPDF(URL.createObjectURL(new Blob([blob])));
    } catch (error) {
      setUrlPDF(null);
      setErreurGenerationPDF(true);
    }
  };

  const nomComplet =
    data?.firstName && data?.lastName
      ? "_" + data?.firstName + "_" + data?.lastName
      : "";

  const telechargerDevis = () => {
    if (urlPDF) {
      downloadFileFromLink(urlPDF, "Devis_PNO" + nomComplet, "pdf");
    }
  };

  return (
    <>
      <Typography
        onClick={() => setAfficherPopinDevis(true)}
        style={{
          color: theme.palette.primary.main,
          textAlign: "center",
          cursor: "pointer",
          textDecoration: "underline",
          marginTop: 5,
        }}
      >
        {t("pno.recapitulatif.visualiserDevis")}
      </Typography>

      <Popin
        title={t("pno.recapitulatif.visualiserDevis")}
        content={<PdfViewVer filePath={urlPDF} error={erreurGenerationPDF} />}
        open={afficherPopinDevis}
        maxWidth="lg"
        onClose={() => {
          setErreurGenerationPDF(false);
          setAfficherPopinDevis(false);
        }}
        showCancelButton={true}
        cancelButtonLabel={t("common.popin.btn_close")}
        isValidateButtonDisabled={!urlPDF || erreurGenerationPDF}
        onValidate={telechargerDevis}
        validateButtonLabel={t("common.download")}
      />
    </>
  );
};
