import { useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Hidden,
} from "@material-ui/core";
import { ExitToApp as ExitToAppIcon } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _get from "lodash/get";
import { useTranslation } from "react-i18next";
import { UserContext } from "@/contexts/user";

import Item from "./Item/index";
import LocaleSelector from "../LocaleSelector/index";
import { items } from "./items";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: 76,
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
  },
  text: {
    fontSize: 14,
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { oktaAuth } = useOktaAuth();
  const [userState, dispatch] = useContext(UserContext);
  const { t } = useTranslation();

  const userName = `${_get(userState, "name.firstName", "")} ${_get(
    userState,
    "user.lastName",
    ""
  )}`;

  const handleDisconnect = async () => {
    dispatch({ type: "REMOVE_USER" });
    await oktaAuth.signOut();
  };

  return (
    <Grid
      style={{ height: "100%" }}
      container
      direction="column"
      justifyContent="space-between"
    >
      <Box className={classes.content}>
        <Hidden smDown implementation="css">
          <Grid container justifyContent="center">
            <Box>
              <img
                src={`${localStorage.getItem(
                  "IMAGE_MANAGER_URL_GET"
                )}/assurimo/base/logo-assurimo.png`}
                alt="Assurimo"
                style={{
                  maxWidth: 220,
                }}
              />
            </Box>
          </Grid>
        </Hidden>
        <Grid container justifyContent="flex-start">
          <Box
            my={4}
            px={2}
            style={{ marginLeft: 16, marginTop: 20, marginBottom: 20 }}
          >
            <Typography variant="subtitle2">
              {t("dashboard.menu.welcome")}{" "}
            </Typography>
            <Typography variant="subtitle2">
              <span style={{ fontWeight: "bold" }}>{userName}</span>
            </Typography>
          </Box>
        </Grid>
        <List>
          {items(t).filter(item => item.show).map((item) => (
            <Item key={item.id} item={item} />
          ))}
        </List>
      </Box>
      <Box pb={4}>
        <List>
          <LocaleSelector variant="sidebar" />
          <ListItem button onClick={handleDisconnect}>
            <ListItemIcon>
              <ExitToAppIcon color="secondary" />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography
                  variant="body1"
                  classes={{ root: classes.text }}
                  style={{
                    color: theme.palette.blue.main,
                  }}
                >
                  {t("dashboard.menu.logout")}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>
    </Grid>
  );
};

export default Sidebar;
