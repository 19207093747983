import React, { useState } from "react";
import PropTypes from "prop-types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";

import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";

import Popin from "@/components/Popin";
import _get from "lodash/get";
import { TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@material-ui/core";
import MuiTable from '@material-ui/core/Table';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    padding: 30,
  },
  valueWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  receipt: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  header: {
    fontWeight: "bolder",
  },
  headerCell: {
    color: theme.palette.blue.light
  }
}));

const formatCurrency = (amount) => {
  if(!amount)  return '';
  return new Intl.NumberFormat('de-DE', { 
    style: 'currency', 
    currency: 'EUR' 
  }).format(amount);
}

const Content = (props) => {
  const { quittances } = props;
  const classes = useStyles();

  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(100);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (pageSize, aa) => {
    setRowsPerPage(pageSize.target.value);
    setPage(0);
  };

  const getColumnsInfo = (quittance) => ({
    type: {
      label: "Type",
      value: _get(quittance, "type.code", null)
    },
    debit: {
      label: "Débit",
      value: _get(quittance, "sens", null) && quittance.sens === "D" ? formatCurrency(quittance.montant) : null
    },
    credit: {
      label: "Crédit",
      value: _get(quittance, "sens", null) && quittance.sens === "C" ? formatCurrency(quittance.montant) : null
    },
    datePrelevement: {
      label: "Date de prélèvement",
      value: _get(quittance, "datePrelevement", null) ? quittance.datePrelevement : null
    },
    status: {
      label: "Statut",
      value: _get(quittance, "etatPPQ", null)
    },
  });

  return (
      <div style={{ maxHeight: "80vh", width: "100%", padding: "40px 30px" }}>
        <TableContainer component={Paper}>
          <MuiTable stickyHeader aria-label="Tableau des polices" size="small">
            <TableHead>
              <TableRow>
                  {
                    Object.values(getColumnsInfo()).map((column, index) => (
                      <TableCell>
                        <Typography key={index} classes={{ root: classes.headerCell }}>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))
                  }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                quittances.map(quittance => 
                <TableRow hover tabIndex={-1} key={quittance.uuid}>
                  {
                    Object.values(getColumnsInfo(quittance)).map((column, index) => (
                      <TableCell classes={{ root: classes.headerCell }}>
                          {column.value}
                      </TableCell>
                    ))
                  }
                </TableRow>)
              }
            </TableBody>
          </MuiTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 30, 100]}
          component="div"
          count={quittances.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePageSizeChange}
          labelRowsPerPage="Lignes par page:"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} sur ${count !== -1 ? count : `plus de ${to}`}`
          }
          getItemAriaLabel={(type) => {
            if (type === 'first') {
              return 'Aller à la première page';
            } else if (type === 'last') {
              return 'Aller à la dernière page';
            } else if (type === 'next') {
              return 'Aller à la page suivante';
            } else if (type === 'previous') {
              return 'Aller à la page précédente';
            }
            return '';
          }}
        />
      </div>
  );
};

Content.propTypes = {
  quittances: PropTypes.array,
};

const NextQuittance = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const quittances = row.premiums;

  const handleViewMoreClick = () => {
    setOpen(!open);
  };

  const handleClose = () => setOpen(false);

  const getTotal = () => {
    const amount = quittances.reduce((total, quittance) => {
      const sens = quittance.sens === "C" ? -1 : 1;
      return (total * 100 + sens * quittance.montant * 100) / 100;
    }, 0);

    return formatCurrency(amount);
  };

  if (quittances && quittances.length > 0) {
    return (
      <>
        <div className={classes.receipt}>
          <span>{getTotal()}</span>
          <IconButton onClick={handleViewMoreClick}>
            <VisibilityIcon />
          </IconButton>
        </div>

        <Popin
          title={`Quittances de la police ${row.policyNumber}`}
          open={open}
          onClose={handleClose}
          content={<Content quittances={quittances} />}
        />
      </>
    );
  } else {
    return <></>;
  }
};

NextQuittance.propTypes = {
  row: PropTypes.shape().isRequired,
};

export default NextQuittance;
