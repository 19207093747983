import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Skeleton from '@material-ui/lab/Skeleton';
import { generateUUID } from '../../../utils/uuid';
import { DataStructure } from 'components/StaticTable';

interface LoadingProps<T> {
  dataStructure: DataStructure<T>[];
}

function Loading<T>(props: LoadingProps<T>) {
  const { dataStructure } = props;

  return (
    <TableBody>
      {Array.from({ length: 5 }).map(() => (
        <TableRow key={generateUUID()}>
          {dataStructure?.map(() => (
            <TableCell key={generateUUID()}>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

export default Loading;
