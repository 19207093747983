import React, { useContext } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";

import { PnoSouscriptionContexte } from "@/views/PnoSouscription";

interface BoutonRetourProps {
  onClick?: () => void;
}
export const BoutonRetour = ({ onClick }: BoutonRetourProps) => {
  const { retourEtapePrecedente } = useContext(PnoSouscriptionContexte);

  const handleClick = onClick ? onClick : retourEtapePrecedente;

  return (
    <Button
      color="primary"
      variant="contained"
      size="large"
      onClick={handleClick}
    >
      <ArrowBackIcon />
    </Button>
  );
};
