import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import TextField from "@/components/TextField";
import SelectField from "@/components/SelectField";
import { DataStructure } from "@/components/StaticTable";
import { FilterPayloadRecord } from "@/types/ApiResponse";

import { OPERATORS, Operator, OperatorDataType } from "./operators";

interface FiltersProps<T> {
  dataStructure: DataStructure<T>[];
  defaultSelectedcolumn: DataStructure<T>;
  onValidate: (newFilter: FilterPayloadRecord) => void;
}
export function Filters<T>(props: FiltersProps<T>) {
  const { defaultSelectedcolumn, dataStructure, onValidate } = props;

  const { t } = useTranslation();

  const [selectedColumn, setSelectedColumn] = useState<DataStructure<T>>(
    defaultSelectedcolumn
  );
  const [selectedOperator, setSelectedOperator] = useState<Operator | null>(
    null
  );
  const [filterValue, setFilterValue] = useState<number | string>("");
  const [filterValueMin, setFilterValueMin] = useState<number | string>("");
  const [filterValueMax, setFilterValueMax] = useState<number | string>("");
  const [selectedFilter, setSelectedFilter] = useState<any>(null);

  const hasValue = filterValue ? true : !!(filterValueMin && filterValueMax);
  const placeHolderText =
    selectedOperator?.value === "IN"
      ? t("common.dmri.filter.placeHolderValueIn")
      : t("common.dmri.filter.placeHolderValue");

  useEffect(() => {
    if (selectedColumn) setSelectedFilter(selectedColumn);
  }, [selectedColumn]);

  const getOperators = (): Operator[] => {
    return OPERATORS.filter((operator) => {
      if (!selectedColumn) return false;
      return operator.type.includes(
        selectedColumn.filter?.rowType as OperatorDataType
      );
    });
  };

  const handleFilterValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    updateFunc: React.Dispatch<React.SetStateAction<number | string>>
  ) => {
    updateFunc(event.target.value);
  };

  const handleFilterValidation = () => {
    if (
      selectedOperator &&
      selectedFilter &&
      selectedFilter.filter &&
      selectedColumn &&
      selectedColumn.filter
    ) {
      const operatorIn = selectedOperator.value === "IN";
      let valueIn = null;

      if (operatorIn) {
        valueIn = filterValue
          .toString()
          .split(",")
          .map((v) => v.trim());
        if (selectedColumn.filter.rowType === "number") {
          valueIn = valueIn.map((v) => +v);
        }
      }

      const newFilter = {
        [selectedFilter.filter.id]: {
          value: filterValue,
          operateur: selectedOperator.value,
          valueIn: valueIn,
          ...(selectedColumn.filter.rowType === "number" && {
            valueMin: filterValueMin,
            valueMax: filterValueMax,
          }),
        },
      };

      onValidate(newFilter as any);
    }
  };

  const isBetweenOperatorSelected = () => {
    return selectedOperator?.value === "BETWEEN" || false;
  };

  return (
    <Box m={2}>
      <Grid container>
        <Grid container item spacing={3}>
          <Grid item md={3}>
            <SelectField
              id="selectedColumn"
              label={t("common.dmri.filter.field")}
              items={dataStructure.filter((column) => column.filter)}
              onChange={(e) => setSelectedColumn(e.target.value)}
              value={selectedColumn}
            />
          </Grid>
          <Grid item md={3}>
            <SelectField
              id="selectedOperator"
              label={t("common.dmri.filter.operator")}
              items={getOperators()}
              onChange={(e) => setSelectedOperator(e.target.value)}
              value={selectedOperator}
            />
          </Grid>
          {isBetweenOperatorSelected() ? (
            <>
              <Grid item md={3}>
                <TextField
                  label={t("common.dmri.filter.valueMin")}
                  onChange={(
                    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => handleFilterValueChange(e, setFilterValueMin)}
                  value={filterValueMin}
                  placeholder={t("common.dmri.filter.placeHolderValueMin")}
                  fullWidth
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={t("common.dmri.filter.valueMax")}
                  onChange={(
                    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => handleFilterValueChange(e, setFilterValueMax)}
                  value={filterValueMax}
                  placeholder={t("common.dmri.filter.placeHolderValueMax")}
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <Grid item md={6}>
              <TextField
                label={t("common.dmri.filter.value")}
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => handleFilterValueChange(e, setFilterValue)}
                value={filterValue}
                placeholder={placeHolderText}
                fullWidth
              />
            </Grid>
          )}
        </Grid>
        <Grid item md={12}>
          <Box mt={3} display="flex" justifyContent="end">
            <Button
              onClick={handleFilterValidation}
              color="primary"
              variant="contained"
              disabled={!(selectedColumn && selectedOperator && hasValue)}
            >
              {t("common.dmri.filter.btnApply")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
