import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Finscan from "@/assets/images/finscan.svg";

import { BoutonRetour } from "@/views/PnoSouscription/BoutonRetour";

interface ErreurFinscanProps {
  retourPageInfo?: () => void;
  erreur: "ko" | "pending";
}
export const ErreurFinscan = ({
  erreur,
  retourPageInfo,
}: ErreurFinscanProps) => {
  const { t } = useTranslation();

  const TEL_SERVICE_PARTICULIER = "04 72 77 89 10";

  return (
    <>
      <Typography variant="h6">{t("pno.analyseFinscanTitre")}</Typography>

      <section>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={Finscan} alt="Contrôle Finscan" />
          <Typography style={{ marginLeft: "20px" }}>
            {erreur === "ko" && t("pno.finscanKO")}
            {erreur === "pending" &&
              t("pno.finscanPending", {
                telServiceParticulier: TEL_SERVICE_PARTICULIER,
              })}
          </Typography>
        </div>
        {retourPageInfo && (
          <div>
            <BoutonRetour onClick={retourPageInfo} />
          </div>
        )}
      </section>
    </>
  );
};
