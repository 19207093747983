import { NavigateFunction, useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

import policyApi from "@/api/policyApi";
import { formatAnniversaryDate, formatPrice } from "@/utils";
import { ApiTablePagine } from "@/components/ApiTablePagine";
import { FilterPayloadRecord, SortPayload } from "@/types/ApiResponse";
import { Police } from "@/types/Police";
import { URLS } from "@/constants/urls";

const DMRIContracts = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getDmriContracts = (
    filters: FilterPayloadRecord,
    sort: SortPayload,
    currentPage: number,
    rowsPerPage: number
  ) =>
    policyApi.getContratsMri({
      page: currentPage,
      sort: sort,
      filter: filters,
      size: rowsPerPage,
    });

  return (
    <>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      ></Grid>

      <br />

      <ApiTablePagine<Police>
        rowIdentifier="policyNumber"
        getData={getDmriContracts}
        dataStructure={[
          {
            renderer: (e) => e.numeroImmeuble,
            label: t("dashboard.dmri.numeroImmeuble"),
            filter: { key: "numImmeuble", rowType: "string" },
          },
          {
            renderer: (e) => e.policyKey,
            label: t("dashboard.dmri.adresse"),
            filter: { key: "clePolice", rowType: "string" },
            align: "left",
          },
          {
            renderer: (e) => e.adresse2,
            label: t("dashboard.dmri.copro"),
          },
          {
            renderer: (e) => e.ville,
            label: t("dashboard.dmri.ville"),
            align: "left",
          },
          {
            renderer: (e) => e.policyNumber,
            label: t("dashboard.dmri.contrat"),
            filter: { key: "numPolice", rowType: "string" },
          },
          {
            renderer: (e) => e.nbSinistres as any,
            label: t("dashboard.dmri.sinEncours"),
          },
          {
            renderer: (e) => formatPrice(e.annualTtc),
            label: t("dashboard.dmri.ttcAn"),
            filter: { key: "ttcAnnuel", rowType: "number" },
          },
          {
            renderer: (e) => formatAnniversaryDate(e.contractAnniversaryDate),
            label: t("dashboard.dmri.echeance"),
          },
          /*{
            renderer: (e) => afficherStatut(e),
            label: t('dashboard.dmri.statut'),
          }*/
          { renderer: (e) => afficherBoutonDetails(e, navigate) },
        ]}
      />
    </>
  );
};

const afficherBoutonDetails = (item: any, navigate: NavigateFunction) => {
  return (
    <Button
      onClick={() => navigate(`${URLS.MRI_DMRI}/${item.policyNumber}`)}
      color="primary"
      variant="contained"
      size="small"
    >
      Detail
    </Button>
  );
};

export default DMRIContracts;
