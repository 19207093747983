import axiosClient from '@/api/axiosClient';

const BASE_URL_V2 = '/v2/premiums';

const premiumApi = {
  generatePremium: async (
    premiumUuid: string,
    codeTypeFavori: string = 'POL_QUITTANCE'
  ) => {
    const url = `${BASE_URL_V2}/${premiumUuid}/ged/download/${codeTypeFavori}`;
    // eslint-disable-next-line no-undef
    const response: BlobPart = await axiosClient.post(url, null, {
      responseType: 'arraybuffer',
    });

    return response;
  },
};

export default premiumApi;
