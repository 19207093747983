import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TableContainer from "@material-ui/core/TableContainer";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import { useSnackbar } from "notistack";

import Popin from "@/components/Popin";
import Loader from "@/components/Loader";

import useStyle from "./style";
import Filters from "./filters";

const Table = (props) => {
  const { dataStructure, getData, isAPageableReponse, rowIdentifier } = props;

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyle();
  const { t } = useTranslation();
  const [response, setReponse] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});

  const [popinOpen, setPopinOpen] = useState(false);
  const [popinContent, setPopinContent] = useState(<></>);

  const data = isAPageableReponse ? response.content : response;

  const fetchData = async (filters, currentPage, elementsPerPage) => {
    try {
      setLoading(true);
      let newResponse;
      if (isAPageableReponse) {
        newResponse = await getData(filters, currentPage, elementsPerPage);
      } else {
        newResponse = await getData(filters);
      }
      setReponse(newResponse);
    } catch (err) {
      // LOCALIZATION
      enqueueSnackbar(err.message, { variant: "error" });
      return [];
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const defaultElementsPerPage = 10;
    fetchData(filters, 0, defaultElementsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCurrentPageChange = (event, newPage) => {
    fetchData(filters, newPage, response.pageable.pageSize);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    fetchData(filters, response.pageable.pageNumber, newRowsPerPage);
  };

  const handleClosePopin = () => {
    setPopinOpen(false);
  };

  const onValidateFilter = (newFilter) => {
    if (isAPageableReponse) {
      fetchData(newFilter, 0, response.pageable.pageSize);
    } else {
      // TODO : implémenter les filtres dans le FRONT
    }

    setFilters(newFilter);
    setPopinOpen(false);
  };

  const handleFilterClick = (filterType, defaultSelectedcolumn) => {
    setPopinOpen(true);
    setPopinContent(
      <Filters
        dataStructure={dataStructure}
        filterType={filterType}
        onValidate={onValidateFilter}
        defaultSelectedcolumn={defaultSelectedcolumn}
      />
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <TableContainer component={Paper}>
        <MuiTable className={classes.table}>
          <TableHead>
            <TableRow>
              {dataStructure?.map((column, index) => (
                <TableCell key={index} classes={{ head: classes.headerCell }}>
                  <div
                    className={classes.headerCellWrapper}
                    style={{
                      display: "flex",
                      justifyContent: column.align || "center",
                    }}
                  >
                    <div>{column.label}</div>
                    {
                      // TODO : Retirer la condition isAPageableReponse quand les filtres FRONT sont implémentés
                      isAPageableReponse &&
                        column.filter?.type === "search" && (
                          <div className={classes.headerCellButtonWrapper}>
                            <SearchIcon
                              onClick={() =>
                                handleFilterClick("search", column)
                              }
                            />
                          </div>
                        )
                    }
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data && data.length !== 0 ? (
              data.map((item) => (
                <TableRow
                  key={item[rowIdentifier]}
                  classes={{ root: classes.tableRow }}
                >
                  {dataStructure?.map((column, index) => (
                    <TableCell
                      key={index}
                      classes={{ root: classes.cell }}
                      align={column?.align || "center"}
                    >
                      {column.renderer(item)}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow classes={{ root: classes.tableRow }}>
                <TableCell
                  classes={{ root: classes.cell }}
                  align={"center"}
                  colSpan={dataStructure.length}
                >
                  {t("common.dmri.paginate.noData")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          {isAPageableReponse && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  colSpan={dataStructure.length}
                  count={response.totalElements}
                  rowsPerPage={response.pageable.pageSize}
                  page={response.pageable.pageNumber}
                  onPageChange={handleCurrentPageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  labelRowsPerPage={t("common.dmri.paginate.labelRowsPerPage")}
                />
              </TableRow>
            </TableFooter>
          )}
        </MuiTable>
      </TableContainer>
      <Popin
        title={t("common.dmri.filter.title")}
        open={popinOpen}
        content={popinContent}
        onClose={handleClosePopin}
        maxWidth="md"
        showActionsButtons={false}
      />
    </>
  );
};

Table.propTypes = {
  rowIdentifier: PropTypes.string.isRequired,
  dataStructure: PropTypes.array.isRequired,
  getData: PropTypes.func.isRequired,
  isAPageableReponse: PropTypes.bool,
};

export default Table;
