import React, { createContext, useReducer } from "react";

const initialState = {};
const UserContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        ...action.payload,
      };
    case "REMOVE_USER":
      return initialState;
    default:
      throw new Error("No matching action type");
  }
};

const UserProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
