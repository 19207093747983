import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  headerLayout: {
    display: "flex",
    backgroundColor: theme.palette.blue.light,
    padding: 20,
    gap: 20,
    marginBottom: 10,
    borderRadius: theme.spacing(2),
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    alignItems: "center",
    "& h5, p": {
      color: theme.palette.white.main,
    },
    "& button": {
      marginLeft: "auto",
      color: theme.palette.white.main,
      borderColor: theme.palette.white.main,
      borderRadius: 200,
    },
  },
}));
