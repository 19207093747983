import React, { ChangeEvent } from "react";

import TextField from "@/components/TextField";

interface ChampPropsBase<T> {
  label: string;
  value?: string | number;
  type: keyof T;
  dataType?: React.InputHTMLAttributes<unknown>["type"];
  onChange: (e: ChangeEvent<HTMLInputElement>, type: keyof T) => void;
  required?: boolean;
  disabled?: boolean;
}

interface ChampPropsText<T> extends ChampPropsBase<T> {
  value?: string;
  dataType?: "text" | "email";
  max?: undefined;
  min?: undefined;
  maxLength?: number;
}

interface ChampPropsNumber<T> extends ChampPropsBase<T> {
  value?: number;
  dataType?: "number";
  max?: number;
  min?: number;
  maxLength?: undefined;
}

export const Champ = <T,>({
  label,
  value,
  type,
  dataType = "text",
  onChange,
  required = true,
  min,
  max,
  maxLength,
  disabled = false,
}: ChampPropsText<T> | ChampPropsNumber<T>) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e, type);
  };

  return (
    <TextField
      label={label}
      value={value || ""}
      onChange={handleChange}
      variant="outlined"
      type={dataType}
      required={required}
      inputProps={{ min: min, max: max, maxLength: maxLength }}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={disabled}
    />
  );
};
