import React, { useEffect, useContext, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { LoginCallback, useOktaAuth } from "@okta/okta-react";

import { useSnackbar } from "notistack";

import { NEEDED_RIGHTS as CONTRACT_NEEDED_RIGHTS } from "@/views/Contracts/neededRights";
import { NEEDED_RIGHTS as PLANNED_PAYMENTS_NEEDED_RIGHTS } from "@/views/TermPolicies/neededRights";
import Contracts from "@/views/Contracts";
import Contract from "@/views/DmriContracts/Contract";
import DmriContracts from "@/views/DmriContracts";
import TermPolicies from "@/views/TermPolicies";
import DashboardLayout from "@/layouts/Dashboard";
import { UserContext } from "@/contexts/user";
import userApi from "@/api/userApi";
import Loader from "@/components/Loader";
import { URLS } from "@/constants/urls";

import AuthorizedRoute from "./AuthorizedRoute/AuthorizedRoute";
import SecureRoute from "./SecureRoute/SecureRoute";
import PnoSouscription from "@/views/PnoSouscription";
import SouscriptionsEnCours from "@/views/SouscriptionsEnCours";

interface IRouterProps {
  config: any;
}
const Router: React.FC<IRouterProps> = (props: IRouterProps) => {
  const [, dispatch] = useContext<any>(UserContext);
  const [loading, setLoading] = useState<boolean>(true);

  const { oktaAuth, authState } = useOktaAuth();

  const { enqueueSnackbar } = useSnackbar();

  const setUser = (payload: {}) => {
    dispatch({ type: "SET_USER", payload });
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const userInfo = await oktaAuth.token.getUserInfo();
      const currentUser = await userApi.getExternalUser();

      setUser({ ...userInfo, ...currentUser });
    } catch (error) {
      enqueueSnackbar("Une erreur est survenue", { variant: "error" });
    } finally {
      setLoading(false);
    }

    try {
      setLoading(true);
      const currentUser = await userApi.getExternalUser();
      setUser({ ...currentUser });
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated) getUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.isAuthenticated]);

  return loading ? (
    <Routes>
      <Route path="/" element={<SecureRoute />} />
      <Route
        path="login/callback"
        element={<LoginCallback loadingElement={<Loader />} />}
      />
    </Routes>
  ) : (
    <>
      <Routes>
        <Route path="/" element={<SecureRoute />}>
          <Route path="" element={<DashboardLayout />}>
            <Route
              path=""
              element={
                <AuthorizedRoute neededRights={CONTRACT_NEEDED_RIGHTS} />
              }
            >
              <Route path="/" element={<Navigate to={URLS.TERM_POLICIES} />} />
              <Route path={URLS.TERM_POLICIES} element={<TermPolicies />} />
            </Route>

            <Route
              path=""
              element={
                <AuthorizedRoute
                  neededRights={PLANNED_PAYMENTS_NEEDED_RIGHTS}
                />
              }
            >
              <Route path={URLS.CONTRATS} element={<Contracts />} />
              <Route
                path={URLS.PNO_SOUSCRIPTION}
                element={<PnoSouscription />}
              />
              <Route
                path={URLS.PNO_SOUSCRIPTION_REPRISE}
                element={<PnoSouscription />}
              />
              <Route
                path={URLS.PNO_SOUSCRIPTIONS_EN_COURS}
                element={<SouscriptionsEnCours />}
              />
              <Route
                path={URLS.MRI}
                element={<Navigate to={URLS.MRI_DMRI} />}
              />
              <Route
                path={URLS.PNO}
                element={<Navigate to={URLS.PNO_CONTRACTS} />}
              />
              <Route path={URLS.MRI_DMRI} element={<DmriContracts />} />
              <Route path={URLS.MRI_DMRI_ID} element={<Contract />} />

              <Route path="*" element={<></>} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default Router;
