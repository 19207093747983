import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import Popin from "@/components/Popin";
import { Souscription } from "@/types/Souscription";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import { AdresseAuto } from "@/views/PnoSouscription/Etapes/Adresse/AdresseAuto";
import { AdresseManuelle } from "@/views/PnoSouscription/Etapes/Adresse/AdresseManuelle";
import { estDepartementDOutreMer } from "@/utils/departement";
import { BoutonSuivant } from "@/views/PnoSouscription/BoutonSuivant";
import { BoutonRetour } from "@/views/PnoSouscription/BoutonRetour";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type AdresseType = getTypeOriginalDepuisPartial<
  Souscription["data"]["contractAddress"]
>;

export const Adresse = () => {
  const { dataSouscription } = useContext(PnoSouscriptionContexte);

  const { t } = useTranslation();

  const adresseParcours: Partial<AdresseType> =
    dataSouscription.data?.contractAddress || {};

  const adresseVide = () => Object.keys(adresseParcours).length === 0;

  const [adresseAuto, setAdresseAuto] = useState<boolean>(adresseVide());
  const [popinAffichee, setPopinAffichee] = useState<boolean>(false);

  useEffect(() => {
    if (
      adresseParcours.zipCode &&
      adresseParcours.zipCode.length > 4 &&
      estDepartementDOutreMer(adresseParcours.zipCode)
    )
      setPopinAffichee(true);
  }, [adresseParcours.zipCode]);

  return (
    <>
      <Typography variant="h6">{t("pno.detailsBien")}</Typography>
      <section>
        {adresseAuto ? (
          <AdresseAuto changerEnModeManuel={() => setAdresseAuto(false)} />
        ) : (
          <AdresseManuelle changerEnModeAuto={() => setAdresseAuto(true)} />
        )}
        <div>
          <BoutonRetour />
          <BoutonSuivant
            estDesactive={
              adresseAuto ||
              (adresseParcours &&
                !!estDepartementDOutreMer(adresseParcours.zipCode!))
            }
          />
        </div>
      </section>
      <Popin
        title={`Exclusion`}
        open={popinAffichee}
        onClose={() => setPopinAffichee(false)}
        content={
          <div style={{ padding: 20, textAlign: "center" }}>
            <Typography variant="h6">
              {t("pno.departementNonCouvert")}
            </Typography>
          </div>
        }
        maxWidth="sm"
      />
    </>
  );
};
