import React, { useContext } from "react";
import { Trans } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import { TextSurbrillance } from "@/views/PnoSouscription/Etapes/Recapitulatif";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import { dateFormatStandard, getDernierJourDeLannee } from "@/utils/date";
interface InfoTarifProps {
  montantPremierPrelevement: number | null;
  montantTaxeAttentat?: number;
}

export const InfoTarif: React.FC<InfoTarifProps> = ({
  montantPremierPrelevement,
  montantTaxeAttentat,
}) => {
  const { dataSouscription } = useContext(PnoSouscriptionContexte);

  const dateDebut = dataSouscription.data?.contractStartDate
    ? dateFormatStandard(new Date(dataSouscription.data?.contractStartDate))
    : "-";
  const dateFin = dataSouscription.data?.contractStartDate
    ? dateFormatStandard(
        getDernierJourDeLannee(
          new Date(dataSouscription.data?.contractStartDate)
        )
      )
    : "-";

  return (
    <div>
      <Typography>
        <Trans
          i18nKey="pno.recapitulatif.reglement.premierReglement"
          values={{
            montantPremierPrelevement: montantPremierPrelevement || "-",
          }}
          components={{ style: TextSurbrillance }}
        />{" "}
        <Trans
          i18nKey="pno.recapitulatif.reglement.taxeAttentat"
          values={{ taxe: montantTaxeAttentat || "-" }}
          components={{ style: TextSurbrillance }}
        />
      </Typography>
      <Typography>
        <Trans
          i18nKey="pno.recapitulatif.reglement.periode"
          values={{ dateDebut, dateFin }}
          components={{ style: TextSurbrillance }}
        />
      </Typography>
    </div>
  );
};
