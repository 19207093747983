import React, { createContext, ReactNode, useEffect, useState } from "react";
import drsApi from "@/api/drsApi";
import { Pays } from "@/types/pays";
import { useTranslation } from "react-i18next";

const initialState = {
  pays: [],
};
const PaysContext = createContext<{ pays: { label: string; value: string }[] }>(
  initialState
);

interface PaysProviderProps {
  children: ReactNode;
}

const PaysProvider = (props: PaysProviderProps) => {
  const { i18n } = useTranslation();
  const [listPays, setListPays] = useState<Pays[]>([]);

  useEffect(() => {
    const fetchPays = async () => {
      const listPays = await drsApi.rechercherListPays();
      setListPays(listPays);
    };

    fetchPays();
  }, []);

  const getListPaysSelonLangueSelectionnee = (): {
    label: string;
    value: string;
  }[] => {
    const currentLanguage = i18n.language;

    if (currentLanguage.includes("en"))
      return listPays.map((pays) => {
        return { label: pays.labelEn, value: pays.labelFr };
      });

    return listPays.map((pays) => {
      return { label: pays.labelFr, value: pays.labelFr };
    });
  };

  return (
    <PaysContext.Provider
      value={{ pays: getListPaysSelonLangueSelectionnee() }}
    >
      {props.children}
    </PaysContext.Provider>
  );
};

export { PaysContext, PaysProvider };
