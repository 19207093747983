import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Chip from "@material-ui/core/Chip";
import { statuses } from "@/constants/common";

import useStyles from "./style";

const Status = ({ statut }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const getStatusColor = () => {
    if ([statuses.EC, statuses.PARCOURS, statuses.PREDECLA].includes(statut))
      return { root: classes.chipWip };
    return { root: classes.chip };
  };

  return (
    <Chip
      label={t(`dashboard.dmri.sinistres.statuses.${statut}`)}
      variant="outlined"
      classes={getStatusColor()}
    />
  );
};

Status.propTypes = {
  statut: PropTypes.string.isRequired,
};

export default Status;
