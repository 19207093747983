import { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";

import Paper from "@material-ui/core/Paper";
import DataGrid from "@/components/DataGrid";

import { makeStyles } from "@material-ui/core/styles";

import Popin from "@/components/Popin";
import _get from "lodash/get";

const useStyles = makeStyles({
  tableContainer: {
    padding: 30,
  },
  valueWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  receipt: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  header: {
    fontWeight: "bolder",
  },
});

const columns = [
  {
    field: "type.code",
    headerName: "Type",
    renderCell: (params) => `${_get(params.row, "type.code", null)}`,
    flex: 1,
  },
  {
    field: "debit",
    headerName: "Débit",
    valueGetter: (params) =>
      _get(params.row, "sens", null) && params.row.sens === "D"
        ? params.row.montant
        : null,
    flex: 1,
  },
  {
    field: "credit",
    headerName: "Crédit",
    valueGetter: (params) =>
      _get(params.row, "sens", null) && params.row.sens === "C"
        ? params.row.montant
        : null,
    flex: 1,
  },
  {
    field: "datePrélèvement",
    headerName: "Date de prélèvement",
    valueGetter: (params) =>
      _get(params.row, "datePrelevement", null)
        ? moment(params.row.datePrelevement).utc(true).format("DD/MM/YYYY")
        : null,
    flex: 1,
  },
];

const Content = (props) => {
  const { quittances } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div style={{ height: "80vh", width: "100%", padding: "40px 30px" }}>
        <DataGrid
          rows={quittances}
          columns={columns}
          getRowId={(row) => row.id}
          loading={false}
        />
      </div>
    </Paper>
  );
};

Content.propTypes = {
  quittances: PropTypes.array,
};

const NextQuittance = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const quittances = row.premiums;

  const handleViewMoreClick = () => {
    setOpen(!open);
  };

  const handleClose = () => setOpen(false);

  const getTotal = () => {
    return quittances.reduce((total, quittance) => {
      const sens = quittance.sens === "C" ? -1 : 1;
      return (total * 100 + sens * quittance.montant * 100) / 100;
    }, 0);
  };

  if (quittances && quittances.length > 0) {
    return (
      <>
        <div className={classes.receipt}>
          <span>{getTotal()} €</span>
          <IconButton onClick={handleViewMoreClick}>
            <VisibilityIcon />
          </IconButton>
        </div>

        <Popin
          title={`Quittances de la police ${row.policyNumber}`}
          open={open}
          onClose={handleClose}
          content={<Content quittances={quittances} />}
        />
      </>
    );
  } else {
    return <></>;
  }
};

NextQuittance.propTypes = {
  row: PropTypes.shape().isRequired,
};

export default NextQuittance;
