import axios from "axios";

const configApi = {
  getConfig: async () => {
    const url = "/conf.json";
    const config = await axios.get(url);

    return config;
  },
};

export default configApi;
