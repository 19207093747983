import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import { AxiosResponse } from "axios";
import StaticTable, { DataStructure } from "@/components/StaticTable";

interface ApiTableProps<T> {
  getData: (...args: any[]) => Promise<AxiosResponse<T[]>>;
  dataStructure: DataStructure<T>[];
  rowIdentifier: keyof T;
}

export function ApiTable<T>(props: ApiTableProps<T>) {
  const { dataStructure, getData, rowIdentifier } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = (await getData()) as unknown as T[];

      setData(response);
    } catch (err: any) {
      enqueueSnackbar(err.toString(), {
        variant: "error",
      });
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StaticTable
      data={data}
      dataStructure={dataStructure}
      identifyRow={(row: T) => row[rowIdentifier]}
      loading={loading}
    />
  );
}
