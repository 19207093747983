import React, { ChangeEvent, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Souscription } from "@/types/Souscription";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import { Champ } from "@/views/PnoSouscription/FormulaireElements/Champ";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type DataSouscription = getTypeOriginalDepuisPartial<Souscription["data"]>;
type Adresse = getTypeOriginalDepuisPartial<
  Souscription["data"]["contractAddress"]
>;

export const Garage = () => {
  const SURFACE_MAX: number = 150;
  const SURFACE_MIN: number = 5;

  const { dataSouscription, setDataSouscription } = useContext(
    PnoSouscriptionContexte
  );
  const { t } = useTranslation();
  const adresse: Partial<Adresse> =
    dataSouscription.data?.contractAddress || {};

  const onChangeAdresse = (
    e: ChangeEvent<HTMLInputElement>,
    type: keyof Adresse
  ) => {
    let oldData = { ...adresse };
    let newData = { ...oldData, [type]: e.target.value };

    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        contractAddress: newData as Adresse,
      },
    });
  };

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    type: keyof DataSouscription
  ) => {
    const newData = e.target.value;
    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        [type]: newData,
      },
    });
  };

  return (
    <>
      <div>
        <Champ
          value={dataSouscription.data?.area}
          label={t("pno.surfaceBien", { surfaceMax: SURFACE_MAX })}
          type={"area"}
          onChange={onChange}
          dataType={"number"}
          max={SURFACE_MAX}
          min={SURFACE_MIN}
        />
        <Champ
          value={adresse.lotNumber}
          label={t("pno.numLotFoncia")}
          type={"lotNumber"}
          onChange={onChangeAdresse}
        />
      </div>
      <div>
        <Champ
          value={adresse.buildingNumber}
          label={t("pno.numBatimentFoncia")}
          type={"buildingNumber"}
          onChange={onChangeAdresse}
        />
      </div>
    </>
  );
};
