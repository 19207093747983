import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, Outlet } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import { Menu as MenuIcon, MenuOpen as MenuOpenIcon } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";

import Sidebar from "@/components/Sidebar/sidebar";
import useStyles from "./style";
import { FilAriane } from "@/components/FilAriane";

const DashboardLayout = () => {
  const theme = useTheme();
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden mdUp implementation="css">
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              {mobileOpen && <MenuOpenIcon />}
              {!mobileOpen && <MenuIcon />}
            </IconButton>
            <Link to="/">
              <img
                src={`${localStorage.getItem(
                  "IMAGE_MANAGER_URL_GET"
                )}/assurimo/base/logo-assurimo-sans-baseline.png`}
                alt="Assurimo"
                style={{
                  maxWidth: 180,
                }}
              />
            </Link>
          </Toolbar>
        </AppBar>
      </Hidden>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Sidebar onMenuItemClick={handleDrawerToggle} />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Sidebar />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.mainContainer}>
        <section className={classes.content}>
          <FilAriane />

          <Outlet />
        </section>
      </main>
    </div>
  );
};

DashboardLayout.propTypes = {
  items: PropTypes.array,
};

export default DashboardLayout;
