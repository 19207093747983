import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    padding: 0,
    marginTop: 10,
    marginBottom: 10,
  },
  selectedTab: {
    ...theme.typography.subtitle1,
    fontWeight: 'bolder',
  },
  tab: {
    textTransform: 'none',
    ...theme.typography.subtitle1,
  },
}));

export default useStyles;
