import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";

import policyApi from "@/api/policyApi";
import Loader from "@/components/Loader/index";
import Header from "./Header/index";
import Body from "./Body/index";

import useStyles from "./style";

const Details = () => {
  const { id } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [policy, setPolicy] = useState(null);
  const [risk, setRisk] = useState(null);

  const getPolicy = async () => {
    try {
      const responsePolice = await policyApi.getPolice(id);
      setPolicy(responsePolice);
      const responseRisk = await policyApi.getMriRiskByPolice(id);
      setRisk(responseRisk);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return policy ? (
    <Paper classes={{ root: classes.wrapper }}>
      <Header policy={policy} />
      <Body policy={policy} riskArea={risk?.area} />
    </Paper>
  ) : (
    <Loader />
  );
};

export default Details;
