import React, { ChangeEvent, ReactElement, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import MuiTabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import useStyles from "./style";
import { TabPanel } from "@/components/TabPanel";

interface TabObj {
  label: string;
  render: () => ReactElement;
}
interface TabsProps {
  tabsList: TabObj[];
}

const Tabs: React.FC<TabsProps> = ({ tabsList }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const classes = useStyles();

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <AppBar classes={{ root: classes.appBar }} position="static">
        <MuiTabs
          indicatorColor="primary"
          textColor="secondary"
          value={currentTab}
          onChange={handleChange}
          aria-label="Tabs"
        >
          {tabsList.map((tab) => (
            <Tab
              key={tab.label}
              classes={{ root: classes.tab, selected: classes.selectedTab }}
              label={tab.label}
            />
          ))}
        </MuiTabs>
      </AppBar>
      <>
        {tabsList.map((tab, index) => {
          return (
            <TabPanel
              key={`panel-${tab.label}`}
              value={currentTab}
              index={index}
            >
              {tab.render()}
            </TabPanel>
          );
        })}
      </>
    </>
  );
};

Tabs.propTypes = {
  tabsList: PropTypes.array.isRequired,
};

export default Tabs;
