import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(() => ({
  recapitulatifLayout: {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    // Section infos
    "& > section:first-of-type": {
      display: "flex",
      flex: 2,
      flexDirection: "column",

      "& > div": {
        marginBottom: "25px",
      },
    },

    // Bloc coordonnées de signature
    "& > div:first-of-type": {
      backgroundColor: "red",
      margin: "auto",
    },

    // Boutons en bas de section
    "& > div:last-of-type": {
      " & section": {
        display: "flex",
        margin: "auto",
        whiteSpace: "nowrap",
        "& > button:first-of-type": { marginRight: 25 },
      },
    },
  },
  boutonsValidation: {},
}));
