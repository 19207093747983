import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import { ASSURIMO_LEGAL_MANSIONS_URL } from "@/constants/assurimo";
import { UserContext } from "@/contexts/user";
import { TextSurbrillance } from "@/views/PnoSouscription/Etapes/Recapitulatif";
import { makeStyles } from "@material-ui/core/styles";

interface ConfirmationsProps {
  montantPremierPrelevement: number | null;
  setIsFormValide: React.Dispatch<React.SetStateAction<boolean>>;
}
export const Confirmations = ({
  montantPremierPrelevement,
  setIsFormValide,
}: ConfirmationsProps) => {
  // @ts-ignore
  const [userState] = useContext(UserContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const nomAgence = userState?.societeRattachement
    ? t("pno.cabinet") + " " + userState?.societeRattachement
    : "-";
  const dateLimitePaiement = moment().add(10, "days").format("DD/MM/YYYY");

  const handleCheck1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked1(event.target.checked);
  };

  const handleCheck2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked2(event.target.checked);
  };

  const AuthenticiteInformation = () => (
    <FormControlLabel
      control={
        <Checkbox
          id={"authenticite"}
          checked={isChecked1}
          onChange={handleCheck1}
          required
        />
      }
      label={
        <Typography
          style={{ fontSize: 16 }}
          component={"label"}
          htmlFor={"authenticite"}
        >
          <Trans
            i18nKey="pno.recapitulatif.confirmations.authenticite"
            values={{
              compte: nomAgence,
              montant: montantPremierPrelevement || "-",
              dateLimitePaiement: dateLimitePaiement,
            }}
            components={{ style: TextSurbrillance }}
          />
        </Typography>
      }
    />
  );

  const AccordPriseConnaissanceDesConditions = () => {
    const ConditionsGenerales = () => (
      <a
        href="/files/Conditions_générales_PNO.pdf"
        download="Conditions_générales.pdf"
        style={{
          color: "inherit",
          fontWeight: "bold",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        Conditions Générales
      </a>
    );

    const IPID = () => (
      <a
        href="/files/IPID_PNO.pdf"
        download="Fiche_IPID.pdf"
        style={{
          color: "inherit",
          fontWeight: "bold",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        la Fiche IPID
      </a>
    );

    const MentionsLegales = () => (
      <a
        href={ASSURIMO_LEGAL_MANSIONS_URL}
        style={{
          color: "inherit",
          fontWeight: "bold",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        Mentions Légales
      </a>
    );

    return (
      <FormControlLabel
        control={
          <Checkbox
            id={"acceptConditions"}
            checked={isChecked2}
            onChange={handleCheck2}
            required
          />
        }
        label={
          <Typography
            style={{ fontSize: 16 }}
            component={"label"}
            htmlFor={"acceptConditions"}
          >
            <Trans i18nKey="pno.recapitulatif.confirmations.conditions">
              <ConditionsGenerales />
              <IPID />
              <MentionsLegales />
            </Trans>
          </Typography>
        }
      />
    );
  };

  useEffect(() => {
    if (isChecked1 && isChecked2) setIsFormValide(true);
    else setIsFormValide(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked1, isChecked2]);

  return (
    <div className={classes.confirmationLayout}>
      <AuthenticiteInformation />
      <AccordPriseConnaissanceDesConditions />
    </div>
  );
};

export const useStyles = makeStyles(() => ({
  confirmationLayout: {
    width: "70%",
    "& > label:first-of-type": { marginBottom: 20 },
  },
}));
