import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import Lottie from "@/components/Lottie";
import ApartmentLottie from "@/assets/lotties/apartment.json";

import useStyles from "../style";

const Header = ({ policy }) => {
  const { id } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.header}>
      <div className={classes.lottieWrapper}>
        <Lottie animationData={ApartmentLottie} isActive autoPlay height={60} />
      </div>
      <div>
        <Typography
          variant="h5"
          classes={{ root: classes.headerTypography }}
        >{`${t("common.dmri.contract.contract")} ${id}`}</Typography>
        <Typography
          variant="body1"
          classes={{ root: classes.headerTypography }}
        >
          {policy.policyKey}
        </Typography>
      </div>
    </div>
  );
};

Header.propTypes = {
  policy: PropTypes.shape({
    policyKey: PropTypes.string.isRequired,
  }).isRequired,
};

export default Header;
