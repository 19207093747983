import { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import bodymovin from "lottie-web";

import useStyles from "./style";

const Lottie = (props) => {
  const {
    animationData,
    destinationFrame,
    isActive,
    autoPlay = false,
    height,
  } = props;

  const classes = useStyles({ height });
  const containerRef = useRef();

  useEffect(() => {
    const anim = bodymovin.loadAnimation({
      wrapper: containerRef.current,
      animType: "svg",
      loop: true,
      animationData: animationData,
      autoplay: autoPlay || isActive,
    });

    anim.play();

    return () => {
      anim.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationData, destinationFrame]);

  return (
    <div className={`${classes.lottie}`}>
      <div
        className={`${classes.container} ${
          autoPlay || isActive ? classes.color : ""
        }`}
        ref={containerRef}
      />
    </div>
  );
};

Lottie.propTypes = {
  animationData: PropTypes.any,
  destinationFrame: PropTypes.any,
  isActive: PropTypes.bool,
  autoPlay: PropTypes.bool,
};

export default Lottie;
