import i18n from 'i18next';
import {
  TypeQuittanceEnum,
  TypeQuittance,
  EtatQuittanceEnum,
  EtatQuittance,
  TypePaiementQuittanceEnum,
  TypePaiementQuittance,
} from 'types/Quittance';

const t = (label: string) => i18n.t(label);
export const getTypeLabel = (typeCode: TypeQuittance) => {
  switch (typeCode) {
    case TypeQuittanceEnum.COMPTANT:
      return t('dashboard.dmri.quittances.types.QCO');
    case TypeQuittanceEnum.RISTOURNE:
      return t('dashboard.dmri.quittances.types.RIS');
    case TypeQuittanceEnum.TERME:
      return t('dashboard.dmri.quittances.types.TER');
    case TypeQuittanceEnum.ACCOMPTE:
      return t('dashboard.dmri.quittances.types.AC');
    /*case TypeQuittanceEnum.REGLEMENT:
      return t('dashboard.dmri.quittances.types.RE');
    case TypeQuittanceEnum.RISTOURNE_NON_CERTIFIE:
      return t('dashboard.dmri.quittances.types.RNC');*/
    default:
      return '-';
  }
};

export const getEtatLabel = (etatCode: EtatQuittance) => {
  switch (etatCode) {
    case EtatQuittanceEnum.REGLEE:
      return t('dashboard.dmri.quittances.etats.REGLEE');
    case EtatQuittanceEnum.A_REGLER:
      return t('dashboard.dmri.quittances.etats.A_REGLER');
    /*case EtatQuittanceEnum.SUPPRIMEE:
      return t('dashboard.dmri.quittances.etats.SUPPRIMEE');
    case EtatQuittanceEnum.EN_COURS:
      return t('dashboard.dmri.quittances.etats.EN_COURS');
    case EtatQuittanceEnum.COMPTE_BANCAIRE:
      return t('dashboard.dmri.quittances.etats.COMPTE_BANCAIRE');
    case EtatQuittanceEnum.PRELEVEMENT:
      return t('dashboard.dmri.quittances.etats.PRELEVEMENT');*/
    case EtatQuittanceEnum.PREAVIS_MISE_EN_DEMEURE:
      return t('dashboard.dmri.quittances.etats.PREAVIS_MISE_EN_DEMEURE');
    case EtatQuittanceEnum.MISE_EN_DEMEURE:
      return t('dashboard.dmri.quittances.etats.MISE_EN_DEMEURE');
    default:
      return '-';
  }
};

export const getTypePaiement = (codeTypePaiement: TypePaiementQuittance) => {
  switch (codeTypePaiement) {
    case TypePaiementQuittanceEnum.CHEQUES:
      return t('dashboard.dmri.quittances.paiements.CH');
    case TypePaiementQuittanceEnum.CARTE_BANCAIRE:
      return t('dashboard.dmri.quittances.paiements.CB');
    case TypePaiementQuittanceEnum.PAIEMENT_AVEC_ENREGISTREMENT_CARTE_BANCAIRE:
      return t('dashboard.dmri.quittances.paiements.ENROLEMENT_CB');
    case TypePaiementQuittanceEnum.PRELEVEMENT_CARTE_BANCAIRE:
      return t('dashboard.dmri.quittances.paiements.PRELEVEMENT_CB');
    default:
      return '-';
  }
};
