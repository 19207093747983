import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  popinLayout: {
    padding: theme.spacing(2),
    "& > div": {
      marginTop: 25,
      marginBottom: 15,
      width: "100%",
    },
    "& > p": {
      fontSize: 14,
      color: theme.palette.primary.main,
    },
    // Cache les flèches de l'input dans Firefox
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    // Cache les flèches de l'input pour les autres navigateurs
    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
  },

  // -------------

  boxTextField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& .errorMsg": {
      color: theme.palette.red.main,
    },
  },
  button: {
    borderRadius: 15,
    margin: theme.spacing(1),
  },
  boxFooter: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    "& .boxButton": {
      display: "flex",
      justifyContent: "center",
    },
  },
  input: {
    // Cacher les flèches dans Chrome, Safari et Edge
  },
}));
