import React, { ReactElement } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

import Icon from "@/components/Icon";
import useStyles from "./style";
import CloseIcon from '@material-ui/icons/Close';
import { Box } from "@material-ui/core";

interface PopinProps {
  open: boolean;
  title?: string;
  onClose: () => void;
  content: ReactElement;
  onCancel?: () => void;
  onValidate?: () => void;
  showActionsButtons?: boolean;
  showCancelButton?: boolean;
  maxWidth?: false | "md" | "xs" | "sm" | "lg" | "xl";
  validateButtonLabel?: string;
  cancelButtonLabel?: string;
  isValidateButtonDisabled?: boolean;
  isCancelButtonDisabled?: boolean;
  enableTitleArea?: boolean;
  //dialogActionsClasses?: any;
  validateButtonVariant?: "text" | "outlined" | "contained";
  useValidateLoader?: boolean;
  validateLoading?: boolean;
  icon?: string;
  disableEscapeKeyDown?: boolean;
}
const Popin: React.FC<PopinProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const {
    open,
    title,
    onClose,
    icon,
    content,
    validateButtonLabel = t("common.popin.btn_close"),
    cancelButtonLabel = t("common.popin.btn_close"),
    showCancelButton = false,
    maxWidth = "md",
    isValidateButtonDisabled = false,
    isCancelButtonDisabled = false,
    onValidate,
    onCancel,
    enableTitleArea = true,
    //dialogActionsClasses,
    validateButtonVariant,
    useValidateLoader = false,
    validateLoading = false,
    showActionsButtons = true,
    disableEscapeKeyDown = false,
  } = props;

  const isDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  const handleValidate = () => {
    if (onValidate) onValidate();
    else onClose();
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    else onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth={maxWidth}
      fullScreen={!!isDownXs}
      //className={classes.dialog}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {enableTitleArea && (
        <DialogTitle
          id="scroll-dialog-title"
          classes={{ root: classes.titleWrapper }}
          disableTypography
        >
          <Box>
            {icon && <Icon className={icon} classes={{ root: classes.icon }} />}
            <Typography classes={{ root: classes.title }}>
            {title}
            </Typography>
          </Box>
          <Box className="boxClose" onClick={onClose}>
            <CloseIcon />
          </Box>
        </DialogTitle>
      )}
      <DialogContent dividers={true} className={classes.content}>
        {content ? content : null}
      </DialogContent>
      {showActionsButtons && (
        <DialogActions className={classes.footerWrapper} >
          {showCancelButton && (
            <Button onClick={handleCancel} disabled={isCancelButtonDisabled}>
              {cancelButtonLabel}
            </Button>
          )}
          <Button
            onClick={handleValidate}
            color="primary"
            disabled={isValidateButtonDisabled}
            variant={validateButtonVariant}
          >
            {useValidateLoader && validateLoading ? (
              <CircularProgress color="primary" size={16} />
            ) : (
              validateButtonLabel
            )}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Popin;
