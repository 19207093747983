import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Router from "@/router/Router";
import axiosClient from "@/api/axiosClient";
import { UserProvider } from "@/contexts/user";
import configApi from "@/api/configApi";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { getOktaAuthConfig } from "@/constants/okta";
import { PaysProvider } from "@/contexts/pays";

const App = () => {
  const [config, setConfig] = useState<any>();
  const navigate = useNavigate();

  const getOktaConfig = async () => {
    const { data } = await configApi.getConfig();
    axiosClient.defaults.baseURL = data.API_END_POINT;

    setConfig(data);
  };

  useEffect(() => {
    getOktaConfig();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getImageManagerURL = () => {
      if (config)
        localStorage.setItem("IMAGE_MANAGER_URL_GET", config.IMAGE_MANAGER_URL);
    };

    if (config) getImageManagerURL();
  }, [config]);

  if (!config) return <></>;

  const oktaAuthConfig = getOktaAuthConfig(config);

  const onAuthRequired = () => {
    navigate("/login");
  };

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security
      oktaAuth={new OktaAuth(oktaAuthConfig)}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      <UserProvider>
        <PaysProvider>
          <Router config={config} />
        </PaysProvider>
      </UserProvider>
    </Security>
  );
};

export default App;
