import React, { ChangeEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";

import Popin from "@/components/Popin";
import { Souscription } from "@/types/Souscription";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import { Champ } from "@/views/PnoSouscription/FormulaireElements/Champ";
import { Checkbox, FormControlLabel } from "@material-ui/core";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type DataSouscription = getTypeOriginalDepuisPartial<Souscription["data"]>;
type Adresse = getTypeOriginalDepuisPartial<
  Souscription["data"]["contractAddress"]
>;
export const LocalCommercial = () => {
  const SURFACE_MAX: number = 350;
  const SURFACE_MIN: number = 5;

  const { dataSouscription, setDataSouscription } = useContext(
    PnoSouscriptionContexte
  );
  const { t } = useTranslation();
  const [popinActivityIsOpen, setPopinActivityIsOpen] = useState(false);

  const adresse: Partial<Adresse> =
    dataSouscription.data?.contractAddress || {};

  const afficherPopinInfo = () => {
    setPopinActivityIsOpen(true);
  };

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    type: keyof DataSouscription
  ) => {
    const newData = e.target.value;
    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        [type]: newData,
      },
    });
  };

  const onChangeAdresse = (
    e: ChangeEvent<HTMLInputElement>,
    type: keyof Adresse
  ) => {
    let oldData = { ...adresse };
    let newData = { ...oldData, [type]: e.target.value };

    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        contractAddress: newData as Adresse,
      },
    });
  };

  return (
    <>
      <div>
        <Champ
          value={dataSouscription.data?.area}
          label={t("pno.surfaceBien", { surfaceMax: SURFACE_MAX })}
          type={"area"}
          onChange={onChange}
          dataType={"number"}
          max={SURFACE_MAX}
          min={SURFACE_MIN}
        />
        <Champ
          value={dataSouscription.data?.typeActivite}
          label={t("pno.typeActivite")}
          type={"typeActivite"}
          onChange={onChange}
        />
      </div>

      <div>
        <Champ
          value={adresse.lotNumber}
          label={t("pno.numLotFoncia")}
          type={"lotNumber"}
          onChange={onChangeAdresse}
        />
        <Champ
          value={adresse.buildingNumber}
          label={t("pno.numBatimentFoncia")}
          type={"buildingNumber"}
          onChange={onChangeAdresse}
        />
      </div>
      <div>
        <FormControlLabel
          control={<Checkbox required color="secondary" />}
          label={t("pno.ativiteNonExclu")}
        />
        <Tooltip title="">
          <IconButton onClick={afficherPopinInfo} style={{ padding: 0 }}>
            <InfoIcon color={"secondary"} />
          </IconButton>
        </Tooltip>
      </div>
      <Popin
        title={t("pno.ativitesExcluesPopin.info_title")}
        open={popinActivityIsOpen}
        onClose={() => setPopinActivityIsOpen(false)}
        content={
          <div style={{ padding: 20, textAlign: "left" }}>
            <Typography>
              {t("pno.ativitesExcluesPopin.activite_list_intro")}
            </Typography>
            <Typography>
              <li>{t("pno.ativitesExcluesPopin.activite_politique")}</li>
              <li>{t("pno.ativitesExcluesPopin.activite_local")}</li>
              <li>{t("pno.ativitesExcluesPopin.activite_industrielle")}</li>
              <li>{t("pno.ativitesExcluesPopin.activite_autre")}</li>
            </Typography>
          </div>
        }
        maxWidth="sm"
      />
    </>
  );
};
