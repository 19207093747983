const OPERATORS = [
  { value: 'EQUALS', label: '=', type: ['string', 'number'] },
  { value: 'NOT_EQUAL', label: '!=', type: ['string', 'number'] },
  {
    value: 'CONTAINS',
    label: 'common.dmri.filter.operators.contains',
    type: ['string'],
  },
  {
    value: 'NOT_CONTAINS',
    label: 'common.dmri.filter.operators.notContains',
    type: ['string'],
  },
  {
    value: 'STARTS_WITH',
    label: 'common.dmri.filter.operators.startsWith',
    type: ['string'],
  },
  {
    value: 'NOT_STARTS_WITH',
    label: 'common.dmri.filter.operators.notStartsWith',
    type: ['string'],
  },
  {
    value: 'END_WITH',
    label: 'common.dmri.filter.operators.endsWith',
    type: ['string'],
  },
  {
    value: 'NOT_END_WITH',
    label: 'common.dmri.filter.operators.notEndsWith',
    type: ['string'],
  },
  { value: 'LESS_THAN', label: '<', type: ['number'] },
  { value: 'LESS_THAN_EQUAL', label: '<=', type: ['number'] },
  { value: 'GREATER_THAN', label: '>', type: ['number'] },
  { value: 'GREATER_THAN_EQUAL', label: '>=', type: ['number'] },
  {
    value: 'IN',
    label: 'common.dmri.filter.operators.in',
    type: ['string', 'number'],
  },
  {
    value: 'BETWEEN',
    label: 'common.dmri.filter.operators.between',
    type: ['number'],
  },
];

export default OPERATORS;
