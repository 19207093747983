export const validerNumeroTel = (numero: string, codePays: string): boolean => {
  const frPhoneRegex = /^(\+33|0)[1-9](\d{2}){4}$/;

  if (codePays === "fr") {
    return frPhoneRegex.test(numero);
  }
  return true;
};

export const formatNumTel = (numero: string): string => {
  // Retirer les caractères non numériques
  const cleaned = numero.replace(/\D/g, "");

  // Vérifier le format du numéro
  if (cleaned.length !== 11 && cleaned.length !== 10) {
    return numero;
  }

  // Formater le numéro en fonction du préfixe
  if (cleaned.startsWith("33")) {
    return cleaned.replace(
      /(\d{2})(\d)(\d{2})(\d{2})(\d{2})(\d{2})/,
      "+$1 $2 $3 $4 $5 $6"
    ); // Formate en groupes de 2 chiffres après le premier
  } else if (cleaned.startsWith("0")) {
    return cleaned
      .replace(/^0/, "0")
      .replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");
  } else {
    return numero;
  }
};
