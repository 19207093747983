import React, { useEffect, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";
import i18next from "i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { UserContext } from "@/contexts/user";
import policyApi from "@/api/policyApi";
import planifiedPaymentsApi from "@/api/planifiedPaymentsApi";
import DataGrid from "@/components/DataGrid";
import Popin from "@/components/Popin";
import { downloadFileFromLink } from "@/utils/index";
import useStyles from "./style";
import getColumns from "./columns";
import 'moment/locale/fr';

const PAYMENT_MODES = {
  prelevement: "PRLV",
  virement: "VIRE",
};

const Contracts = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user] = useContext(UserContext);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [selectedPlanifiedPayment, setSelectedPlanifiedPayment] =
    useState(null);
  const [planifiedPayments, setPlanifiedPayments] = useState([]);
  const [showPlaceholder, setShowPlaceholder] = useState(
    selectedPlanifiedPayment === "none"
  );
  const [commentPopinOpen, setCommentPopinOpen] = useState(false);
  const [comment, setComment] = useState(null);
  const [policyNumberChangingPaymentMode, setPolicyNumberChangingPaymentMode] =
    useState(null);

  moment.locale(i18next.resolvedLanguage);

  const getContracts = async (planifiedPayment) => {
    try {
      setLoading(true);

      const response =
        await policyApi.getPoliciesByOfficeCodeAndPlanifiedPayment(
          user.auth,
          user.societeRattachement,
          planifiedPayment.uuid
        );

      if (Array.isArray(response)) setRows(response);
    } catch (err) {
      navigate("/forbiddenAccess");
      enqueueSnackbar(err.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const onChangePaymentMode = (row) => {
    let newPaymentMode;

    if (row.premiums[0].paymentMethod.code === PAYMENT_MODES.prelevement) {
      newPaymentMode = PAYMENT_MODES.virement;

      updateRow(row, newPaymentMode);
      setPolicyNumberChangingPaymentMode(row.policyNumber);
      setCommentPopinOpen(true);
    } else {
      newPaymentMode = PAYMENT_MODES.prelevement;
      updateRow(row, newPaymentMode);
      try {
        changePaymentMode(row.policyNumber, newPaymentMode);
      } catch {
        updateRow(row, PAYMENT_MODES.virement);
      }
    }
  };

  const updateRow = (rowToUpdate, paymentMode) => {
    const newRows = rows.map((row) => {
      if (rowToUpdate.policyNumber === row.policyNumber) {
        const newPremiums = row.premiums.map((premium) => {
          return { ...premium, paymentMethod: { code: paymentMode } };
        });

        return { ...row, premiums: newPremiums };
      } else return row;
    });

    setRows(newRows);
  };

  const changePaymentMode = async (policyNumber, newPaymentMode) => {
    try {
      await policyApi.changePaymentMode(
        user.auth,
        user.societeRattachement,
        policyNumber,
        newPaymentMode,
        comment,
        selectedPlanifiedPayment.uuid
      );
      return Promise.resolve();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
      return Promise.reject();
    } finally {
      getContracts(selectedPlanifiedPayment);
    }
  };

  const getPlanifiedPayments = async () => {
    try {
      setLoading(true);

      const response = await planifiedPaymentsApi.getPlanifiedPayments(
        user.auth
      );

      setPlanifiedPayments(response);
      return response;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeComment = (event, value) => {
    setComment(value);
  };

  const handleCloseCommentPopin = () => {
    setComment(null);
    setCommentPopinOpen(false);
    setPolicyNumberChangingPaymentMode(null);

    const row = rows.find(
      (row) => row.policyNumber === policyNumberChangingPaymentMode
    );
    updateRow(row, PAYMENT_MODES.prelevement);
  };

  const handleValidateComment = () => {
    try {
      changePaymentMode(
        policyNumberChangingPaymentMode,
        PAYMENT_MODES.virement
      );
    } catch {
      const row = rows.find(
        (row) => row.policyNumber === policyNumberChangingPaymentMode
      );
      updateRow(row, PAYMENT_MODES.prelevement);
    }

    setPolicyNumberChangingPaymentMode(null);

    handleCloseCommentPopin();
  };

  const handleSearch = () => {
    getContracts(selectedPlanifiedPayment);
  };

  useEffect(() => {
    getPlanifiedPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (planifiedPayments.length > 0) {
      const firstPlanifiedPayment = planifiedPayments[0];
      setSelectedPlanifiedPayment(firstPlanifiedPayment);
      getContracts(firstPlanifiedPayment);
    }
  }, [planifiedPayments]);// eslint-disable-line react-hooks/exhaustive-deps

  const handleExport = async () => {
    const { uuid } = selectedPlanifiedPayment;
    if (!uuid || !rows.length) return;
    setLoadingExport(true);
    try {
      const blob = await planifiedPaymentsApi.exportPaymentPlanned(
        user.auth,
        uuid,
        user.societeRattachement
      );
      const url = window.URL.createObjectURL(new Blob([blob]));
      downloadFileFromLink(url, uuid, "csv");
    } catch (error) {
      enqueueSnackbar(
        // eslint-disable-next-line quotes
        "Une erreur est survenue, veuillez contacter l'équipe technique",
        { variant: "error" }
      );
    } finally {
      setLoadingExport(false);
    }
  };

  const showBtnExport =
    selectedPlanifiedPayment?.uuid && rows.length && !loading;

  return (
    <>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          className={classes.title}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box />
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Paper classes={{ root: classes.paper }}>
              <FormControl size="small">
                <Select
                  variant="outlined"
                  onChange={(e) => setSelectedPlanifiedPayment(e.target.value)}
                  onFocus={() => setShowPlaceholder(false)}
                  onClose={(e) =>
                    setShowPlaceholder(e.target.value === undefined)
                  }
                  value={selectedPlanifiedPayment}
                  size="small"
                >
                  <MenuItem
                    disabled={!showPlaceholder}
                    value="none"
                    className={!showPlaceholder ? classes.menuItemHiden : null}
                  >
                    {t("dashboard.plannedPayments.selectPlannedPayment")}
                  </MenuItem>
                  {planifiedPayments.map((planifiedPayment) => {
                    return (
                      <MenuItem
                        key={planifiedPayment.uuid}
                        value={planifiedPayment}
                      >
                        {planifiedPayment.dateFait
                          ? `${t("dashboard.plannedPayments.doneOn")} ${moment(
                              planifiedPayment.dateFait
                            ).locale('fr').format("DD MMMM yyyy")}`
                          : `${t(
                              "dashboard.plannedPayments.plannedFor"
                            )} ${moment(planifiedPayment.datePlanifiee).locale('fr').format(
                              "DD MMMM yyyy"
                            )}`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box className={classes.searchButtonWrapper}>
                {!!showBtnExport && (
                  <Button
                    className={classes.searchButton}
                    variant="contained"
                    color="primary"
                    endIcon={
                      loadingExport ? (
                        <CircularProgress color="#cccccc" size={16} />
                      ) : (
                        <ImportExportIcon />
                      )
                    }
                    onClick={handleExport}
                  >
                    {t("common.export")}
                  </Button>
                )}

                <Button
                  className={classes.searchButton}
                  variant="contained"
                  color="secondary"
                  endIcon={
                    loading ? (
                      <CircularProgress color="#cccccc" size={16} />
                    ) : (
                      <SearchIcon />
                    )
                  }
                  onClick={handleSearch}
                >
                  {t("common.search")}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: "80vh", width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={getColumns(user, onChangePaymentMode)}
              getRowId={(row) => row.policyNumber}
              loading={loading}
            />
          </div>
        </Grid>
      </Grid>
      <Popin
        title={"Quelle est la raison du refus de prélèvement ?"}
        open={commentPopinOpen}
        onClose={handleCloseCommentPopin}
        validateButtonLabel={t("common.popin.btn_validate")}
        cancelButtonLabel={t("common.popin.btn_close")}
        isValidateButtonDisabled={!comment}
        onValidate={handleValidateComment}
        showCancelButton={true}
        content={
          <DialogContent>
            <AutoComplete
              onInputChange={handleChangeComment}
              options={[
                { label: "Perte de mandat" },
                { label: "Manque de trésorerie" },
              ]}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  label="Raison du refus"
                  variant="outlined"
                />
              )}
              fullWidth
              freeSolo
            />
          </DialogContent>
        }
      />
    </>
  );
};

export default Contracts;
