import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";

import { formatDate, formatPrice } from "@/utils";
import Table from "@/components/NewTable/index";
import policyApi from "@/api/policyApi";

import Status from "./status";

const Claims = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const isInProgress = (statusCode) => {
    return ["EC", "PREDECLA", "PARCOURS"].includes(statusCode);
  };

  const sortClaims = (claims) => {
    const sortByStatus = (claim1, claim2) => {
      const { code: code1 } = claim1.status;
      const { code: code2 } = claim2.status;

      if (isInProgress(code1) && isInProgress(code2)) {
        return sortByDate(claim1, claim2);
      } else if (isInProgress(code1) && !isInProgress(code2)) {
        return -1;
      } else if (!isInProgress(code1) && isInProgress(code2)) {
        return 1;
      } else if (!isInProgress(code1) && !isInProgress(code2)) {
        return sortByDate(claim1, claim2);
      }
    };

    const sortByDate = (claim1, claim2) => {
      if (moment(claim1.eventDate) < moment(claim2.eventDate)) return 1;
      else if (moment(claim1.eventDate) > moment(claim2.eventDate)) return -1;
      else return 1;
    };

    claims.sort((claim1, claim2) => sortByDate(claim1, claim2));

    return claims.sort((claim1, claim2) => sortByStatus(claim1, claim2));
  };

  const getClaims = async () => {
    const claims = await policyApi.getPoliceClaims(id);

    return sortClaims(claims);
  };

  return (
    <Table
      rowIdentifier="claimsNumber"
      getData={getClaims}
      dataStructure={[
        {
          renderer: (e) => e.claimsNumber,
          label: t("dashboard.dmri.sinistres.numeroImmeuble"),
          filter: { type: "search", rowType: "string" },
        },
        {
          renderer: (e) => e.policy.customer.customerLink,
          label: t("dashboard.dmri.sinistres.numeroFoncia"),
          filter: { type: "search", rowType: "string" },
        },
        {
          renderer: (e) => e.nature.label,
          label: t("dashboard.dmri.sinistres.nature"),
          filter: { type: "search", rowType: "string" },
          align: "left",
        },
        {
          renderer: (e) => formatPrice(e.cost),
          label: t("dashboard.dmri.sinistres.evaluation"),
          filter: { type: "search", rowType: "number" },
        },
        {
          renderer: (e) => formatDate(e.openingDate, "DD/MM/YYYY"),
          label: t("dashboard.dmri.sinistres.creation"),
          filter: { type: "search", rowType: "string" },
        },
        {
          renderer: (e) => <Status statut={e.status.code} />,
          label: t("dashboard.dmri.sinistres.statut"),
          filter: { type: "search", rowType: "string" },
        },
      ]}
      isAPageableReponse={false}
    />
  );
};

export default Claims;
