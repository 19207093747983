import React, { ChangeEvent } from "react";

import {
  Select as SelectUI,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { generateUUID } from "@/utils/uuid";

interface SelectItem {
  value: string;
  label: string;
}
interface SelectProps<T> {
  label: string;
  items: SelectItem[];
  value?: string;
  type?: keyof T;
  onChange: (e: ChangeEvent<HTMLInputElement>, type?: keyof T) => void;
  required?: boolean;
  disabled?: boolean;
}

export const Select = <T,>({
  label,
  value,
  type,
  onChange,
  items,
  required = true,
  disabled = false,
}: SelectProps<T>) => {
  const { t } = useTranslation();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e, type);
  };

  const selectId = "select-label" + (type?.toString() || "default");
  const selectLabel = `${label} ${required && "*"}`;

  return (
    <FormControl variant="outlined" disabled={disabled}>
      <InputLabel id={selectId} shrink disabled={disabled}>
        {selectLabel}
      </InputLabel>
      <SelectUI
        labelId={selectId}
        value={value || ""}
        onChange={(e) => handleChange(e as ChangeEvent<HTMLInputElement>)}
        required={required}
        label={selectLabel}
        disabled={disabled}
      >
        <MenuItem value="">
          <em>{t("common.aucuneSelection")}</em>
        </MenuItem>
        {items.map((item) => (
          <MenuItem key={generateUUID()} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </SelectUI>{" "}
    </FormControl>
  );
};
