import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import useStyles from "../style";
import { formatSecuriseIBAN } from "@/utils/string";
import { calculerDateEcheance } from "@/utils/index";
import PropTypes from "prop-types";
import Status from "./Status";

const Body = ({ policy, riskArea }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.lineWrapper}>
        <div className={classes.line}>
          <BlocInfo
            titre={t("dashboard.dmri.contract.numeroImmeuble")}
            valeur={policy.numeroImmeuble}
          />
          <BlocInfo
            titre={t("dashboard.dmri.contract.immatriculation")}
            valeur={policy.immatCopro}
          />
          <BlocInfo
            titre={t("dashboard.dmri.contract.ibanCopropriete")}
            valeur={formatSecuriseIBAN(policy.ibanEntity)}
          />
          <BlocInfo
            titre={t("dashboard.dmri.contract.surface")}
            valeur={riskArea && riskArea + " m²"}
          />
        </div>
        <Status police={policy} />
      </div>
      <div className={classes.separator} />
      <div className={classes.line}>
        <BlocInfo
          titre={t("dashboard.dmri.contract.ttcAnnuel")}
          valeur={`${policy.annualTtc} €`}
        />
        <BlocInfo
          titre={t("dashboard.dmri.contract.dateEffet")}
          valeur={moment(policy.startDate).format("DD/MM/YYYY")}
        />
        <BlocInfo
          titre={t("dashboard.dmri.contract.echeance")}
          valeur={moment(
            calculerDateEcheance(policy.contractAnniversaryDate)
          ).format("MM/DD")}
        />
      </div>
    </>
  );
};

Body.propTypes = {
  policy: PropTypes.shape({
    numeroImmeuble: PropTypes.string.isRequired,
    area: PropTypes.number,
    immatCopro: PropTypes.string,
    annualTtc: PropTypes.number,
    ibanEntity: PropTypes.string,
    contractAnniversaryDate: PropTypes.number,
    startDate: PropTypes.string,
  }).isRequired,
  riskArea: PropTypes.number,
};

const BlocInfo = ({ titre, valeur }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="caption" classes={{ root: classes.title }}>
        {titre}
      </Typography>
      <Typography variant="body2">{valeur || "-"}</Typography>
    </div>
  );
};
BlocInfo.propTypes = {
  titre: PropTypes.string.isRequired,
  valeur: PropTypes.string,
};

export default Body;
