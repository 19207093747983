import React, { ChangeEvent } from "react";

import TextField from "@/components/TextField";

interface DatePickerProps<T> {
  label: string;
  value?: string;
  type: keyof T;
  onChange: (e: ChangeEvent<HTMLInputElement>, type: keyof T) => void;
  required?: boolean;
  maxDate?: Date;
  minDate?: Date;
  erreur?: string | null;
  disabled?: boolean;
}

export const DatePicker = <T,>({
  label,
  value,
  type,
  onChange,
  required = true,
  minDate,
  maxDate,
  erreur,
  disabled = false,
}: DatePickerProps<T>) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e, type);
  };

  return (
    <TextField
      label={label}
      value={value}
      type={"date"}
      variant={"outlined"}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        max: maxDate && maxDate.toISOString().split("T")[0],
        min: minDate && minDate.toISOString().split("T")[0],
      }}
      required={required}
      error={!!erreur}
      helperText={erreur}
      disabled={disabled}
    />
  );
};
