import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";

const useAuth = () => {
  const { authState } = useOktaAuth();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const {
        accessToken: { accessToken: token },
      } = authState;
      setAuthenticated(true);
      setToken(token);
    } else {
      setAuthenticated(false);
      setToken(null);
    }
  }, [authState]);

  return { isAuthenticated, token };
};

export default useAuth;
