const getOktaAuthConfig = (config, onAuthRequired) => ({
  issuer: config.OKTA_ISSUER,
  clientId: config.OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/login/callback",
  postLogoutRedirectUri: window.location.origin + "/login",
  onAuthRequired: onAuthRequired,
  useInteractionCodeFlow: true,
});

const getOktaSignInConfig = (config) => ({
  logo: `${localStorage.getItem(
    "IMAGE_MANAGER_URL_GET"
  )}/assurimo/base/logo-assurimo.png`,
  baseUrl: config.OKTA_URL,
  clientId: config.OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/login/callback",
  postLogoutRedirectUri: window.location.origin + "/login",
  language: "fr",
  authParams: {
    issuer: config.OKTA_ISSUER,
    // scopes: ['openid', 'profile', 'email'],
  },
});

export { getOktaAuthConfig, getOktaSignInConfig };
