import axiosClient from "@/api/axiosClient";
import moment from "moment";

import { Tarif } from "@/types/Tarif";
import { Souscription } from "@/types/Souscription";
import { PlanDePaiement } from "@/types/Tarif";
import { getDernierJourDeLannee } from "@/utils/date";

const API_BASE_URL = "/v3/ratings";
const API_POLICY_V2 = "/v2/policies";

const ORIGINAL_CODE = "Espace PRO";
const PAIEMENT_PLAN_ANNUEL_CODE = "A";

const tarifApi = {
  async getTarif(data: Souscription["data"]) {
    const url = `${API_BASE_URL}/PNO`;
    const body = {
      originalCode: ORIGINAL_CODE,
      returnAll: true,
      risk: {
        address: data.contractAddress,
        housingType: data.housingType,
        numberPieces: data.numberOfPrincipalRooms,
        furnished: data.furnished,
        area: data.area,
        floor: data.floor,
        equipment: {},
      },
      promotionCode: data.promotionCode ? data.promotionCode : null,
    };

    const response = await axiosClient.post<Tarif>(url, body, {
      responseType: "json",
    });

    //@ts-ignore
    return response as Tarif;
  },

  async getPremierPrelevement(annualTTC: number, dateDebutContrat: Date) {
    const url = `${API_POLICY_V2}/paymentplan`;
    const body = {
      amountInclTax: annualTTC,
      amountPaymentSplittingCode: PAIEMENT_PLAN_ANNUEL_CODE,
      startDate: moment(dateDebutContrat).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      endDate: moment(getDernierJourDeLannee(dateDebutContrat)).format(
        "YYYY-MM-DDTHH:mm:ss[Z]"
      ),
      ratingCode: "PNOSECUR",
      paymentSplittingCodes: [PAIEMENT_PLAN_ANNUEL_CODE],
    };

    const response = await axiosClient.post<PlanDePaiement>(url, body, {
      responseType: "json",
    });

    //@ts-ignore
    return response as FirstPaiementPlan;
  },
};

export default tarifApi;
