import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

interface BoxRecapProps {
  titre: string;
  sousTitre1?: string;
  sousTitre2?: string;
}
export const BoxRecap = ({ titre, sousTitre1, sousTitre2 }: BoxRecapProps) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "10px",
        textAlign: "center",
        border: "1px solid #4E5BA6",
        borderRadius: "10px",
        backgroundColor: "#EEF6FB",
        width: "350px",
      }}
    >
      <Box>
        <Typography style={{ fontWeight: "bold" }}>{titre}</Typography>
        <Typography>
          <span>{sousTitre1 || "-"}</span>
        </Typography>
        <Typography>
          <span>{sousTitre2}</span>
        </Typography>
      </Box>
    </div>
  );
};
