import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import EN from "./en";
import FR from "./fr";
import { LANGUAGES } from "@/constants/common";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupSessionStorage: "i18nextLng",
    },
    cleanCode: true,
    supportedLngs: [LANGUAGES.fr, LANGUAGES.en],
    nonExplicitSupportedLngs: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
    resources: {
      fr: FR,
      en: EN,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
