import React, { ChangeEvent, useContext } from "react";
import { useTranslation } from "react-i18next";

import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import Typography from "@material-ui/core/Typography";
import { Radio } from "@/views/PnoSouscription/FormulaireElements/Radio";
import { Maison } from "@/views/PnoSouscription/Etapes/TypeBien/Maison";
import { Appartement } from "@/views/PnoSouscription/Etapes/TypeBien/Appartement";
import { Garage } from "@/views/PnoSouscription/Etapes/TypeBien/Garage";
import { LocalCommercial } from "@/views/PnoSouscription/Etapes/TypeBien/LocalCommercial";
import { BoutonRetour } from "@/views/PnoSouscription/BoutonRetour";
import { BoutonSuivant } from "@/views/PnoSouscription/BoutonSuivant";
import { Souscription } from "@/types/Souscription";

const PNO_TYPE_MAISON = "M";
const PNO_TYPE_APPARTEMENT = "A";
const PNO_TYPE_GARAGE = "BG";
const PNO_TYPE_LOCAL_COMMERCIAL = "LC";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type Adresse = getTypeOriginalDepuisPartial<
  Souscription["data"]["contractAddress"]
>;
export const TypeBien = () => {
  const { dataSouscription, setDataSouscription } = useContext(
    PnoSouscriptionContexte
  );
  const { t } = useTranslation();

  const typeBien = dataSouscription.data?.housingType;

  const onChangeTypeBien = (e: ChangeEvent<HTMLInputElement>) => {
    const newData = e.target.value;
    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        housingType: newData,
        furnished: undefined,
        area: undefined,
        numberOfPrincipalRooms: undefined,
        floor: undefined,
        typeActivite: undefined,
        monument: undefined,
        contractAddress: {
          ...dataSouscription.data?.contractAddress,
          residency: "",
          lotNumber: "",
          building: "",
          buildingNumber: "",
        } as Adresse,
      },
    });
  };

  return (
    <>
      <Typography variant="h6">{t("pno.detailsBien")}</Typography>
      <section>
        <div>
          <Radio
            value={typeBien || ""}
            items={[
              { label: t("pno.maison"), value: PNO_TYPE_MAISON },
              {
                label: t("pno.appartement"),
                value: PNO_TYPE_APPARTEMENT,
              },
              {
                label: t("pno.localCommercial"),
                value: PNO_TYPE_LOCAL_COMMERCIAL,
              },
              { label: t("pno.garage"), value: PNO_TYPE_GARAGE },
            ]}
            onChange={onChangeTypeBien}
          />
        </div>
        {typeBien === PNO_TYPE_MAISON && <Maison />}
        {typeBien === PNO_TYPE_APPARTEMENT && <Appartement />}
        {typeBien === PNO_TYPE_LOCAL_COMMERCIAL && <LocalCommercial />}
        {typeBien === PNO_TYPE_GARAGE && <Garage />}
        <div>
          <BoutonRetour />
          <BoutonSuivant />
        </div>
      </section>
    </>
  );
};
