import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  lottie: {
    display: "flex",
    justifyContent: "center",
    height: (props) => props.height || 60,
    width: (props) => props.height || 60,
    margin: "auto",
  },
  container: {
    filter: "grayscale(0.85)",
    transition: "filter 0.3s ease-out",
  },
  hovered: {
    filter: "grayscale(0)",
  },
  isFloor: {
    filter: "none !important",
    position: "absolute",
    top: 0,
    transform: "translateY(-5%)",
    width: 191,
  },
  "@media screen and (max-width: 960px)": {
    isFloor: {
      display: "none",
    },
  },
  color: {
    filter: "none !important",
  },
}));

export default useStyles;
