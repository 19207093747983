import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Popin from "@/components/Popin";
import { URLS } from "@/constants/urls";

export const BoutonFinaliserPlusTard = () => {
  const { t } = useTranslation();

  const [affichePopinFinaliserPlustard, setAffichePopinFinaliserPlustard] =
    useState(false);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={() => setAffichePopinFinaliserPlustard(true)}
      >
        {t("pno.recapitulatif.finaliserPlusTard")}
      </Button>
      {affichePopinFinaliserPlustard && (
        <PopinFinaliserPlustard
          fermerPopin={() => setAffichePopinFinaliserPlustard(false)}
        />
      )}
    </>
  );
};

interface PopinFinaliserPlustardProps {
  fermerPopin: () => void;
}

const PopinFinaliserPlustard = ({
  fermerPopin,
}: PopinFinaliserPlustardProps) => {
  const { t } = useTranslation();
  const redirect = useNavigate();

  return (
    <Popin
      open={true}
      onClose={fermerPopin}
      content={
        <div style={{ padding: 20 }}>
          {t("pno.recapitulatif.enregistreParcoursInfo")}
        </div>
      }
      title={t("pno.recapitulatif.finaliserPlusTard")}
      showCancelButton={true}
      cancelButtonLabel={t("pno.recapitulatif.annuler")}
      validateButtonLabel={t("pno.recapitulatif.valider")}
      onValidate={() => redirect(URLS.PNO_SOUSCRIPTIONS_EN_COURS)}
      maxWidth={"sm"}
    />
  );
};
