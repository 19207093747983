import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  headerCell: {
    backgroundColor: theme.palette.blue.light,
    color: theme.palette.common.white,
    height: '74px',
    padding: theme.spacing(2),
    fontWeight: 'bold', 
  },
  headerCellWrapper: {
    display: 'flex',
    alignItems:'center',
    textAlign: 'center'
  },
  headerCellButtonWrapper: {
    display: 'flex',
    marginLeft: 10,
    cursor: 'pointer'
  },
  cell: {
    fontSize: 14,
    color: '#293056',
    fontWeight: 'bold'
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#EEF6FB',
    },
  }
}));

export default useStyles;
