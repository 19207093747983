export const LANGUAGES = {
  en: 'en',
  fr: 'fr',
};

export const TRANSLATE_MONTH = {
  1: 'common.monthLabel.january',
  2: 'common.monthLabel.february',
  3: 'common.monthLabel.march',
  4: 'common.monthLabel.april',
  5: 'common.monthLabel.may',
  6: 'common.monthLabel.june',
  7: 'common.monthLabel.july',
  8: 'common.monthLabel.august',
  9: 'common.monthLabel.september',
  10: 'common.monthLabel.october',
  11: 'common.monthLabel.november',
  12: 'common.monthLabel.december',
};

export const statuses = {
  EC: 'EC',
  PREDECLA: 'PREDECLA',
  PARCOURS: 'PARCOURS',
  CLOSTER: 'CLOSTER',
  CLOSS: 'CLOSS',
};

export const typeBien = {
    MAISON : 'M',
    APPARTEMENT : 'A',
    MONUMENT_HISTORIQUE : 'AMH',
    GARAGE : 'BG',
    LOCAL_COMMERCIAL : 'LC',
};

