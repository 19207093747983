import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import { useTheme } from "@material-ui/core/styles";

import Icon from "@/components/Icon";
import useStyles from "./style";
import { useTranslation } from "react-i18next";

const Item = ({ item }) => {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const isOneOfSubItemsActive = (item) =>
    !!item.subItems?.find((subItem) =>
      location.pathname.startsWith(subItem.link)
    );

  const [open, setOpen] = useState(isOneOfSubItemsActive(item));

  const renderItem = (item, isSub) => {
    const isActive =
      item.link === "/"
        ? location.pathname === "/"
        : location.pathname.startsWith(item.link) ||
          isOneOfSubItemsActive(item);

    const handleClick = () => {
      !isSub && !open && setOpen(!open);
    };

    return (
      <>
        <ListItem
          button
          component={Link}
          replace={true}
          to={item.link}
          onClick={handleClick}
          className={!isSub ? classes.item : classes.subItem}
        >
          <Icon
            className={item.icon}
            color={isActive ? "primary" : "secondary"}
            classes={{ root: classes.icon }}
          />
          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="body1"
                classes={{ root: classes.text }}
                style={{
                  color: isActive
                    ? theme.palette.orange.main
                    : theme.palette.blue.main,
                }}
              >
                {t(item.label)}
              </Typography>
            }
          />
        </ListItem>
        {item.subItems && renderSubItems(item.subItems)}
      </>
    );
  };

  const renderSubItems = (subItems) => {
    return (
      <Collapse in={open}>
        <List component="div" disablePadding>
          {subItems.filter(subItem => subItem.show).map((subItem, index) => (
            <div key={index}>{renderItem(subItem, true)}</div>
          ))}
        </List>
      </Collapse>
    );
  };

  return renderItem(item);
};

Item.propTypes = {
  item: PropTypes.shape(),
};

export default Item;
