import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";

import { BoxRecap } from "@/views/PnoSouscription/BoxRecap";
import { BoutonRetour } from "@/views/PnoSouscription/BoutonRetour";
import { BoutonSuivant } from "@/views/PnoSouscription/BoutonSuivant";
import { BoutonFinaliserPlusTard } from "@/views/PnoSouscription/BoutonFinaliserPlustard";
import { InfoClient } from "@/views/PnoSouscription/Etapes/Recapitulatif/InfoClient";
import { InfoBien } from "@/views/PnoSouscription/Etapes/Recapitulatif/InfoBien";
import { InfoTarif } from "@/views/PnoSouscription/Etapes/Recapitulatif/InfoTarif";
import { BlocTarif } from "@/views/PnoSouscription/Etapes/Recapitulatif/BlocTarif";
import { Confirmations } from "@/views/PnoSouscription/Etapes/Recapitulatif/Confirmations";
import Loader from "@/components/Loader";
import { Tarif } from "@/types/Tarif";
import { UserContext } from "@/contexts/user";
import { useStyles } from "./style";
import tarifApi from "@/api/tarifApi";
import theme from "@/theme";

export const Recapitulatif = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { dataSouscription } = useContext(PnoSouscriptionContexte);
  const [isFormValide, setIsFormValide] = useState(false);

  // @ts-ignore
  const [userState] = useContext(UserContext);
  const [tarifInfo, setTarifInfo] = useState<Tarif | null>(null);
  const [montantPremierPaiement, setMontantPremierPaiement] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (dataSouscription.data?.contractAddress?.address1) {
      tarifApi
        .getTarif(dataSouscription.data || {})
        .then(setTarifInfo)
        .catch(() =>
          enqueueSnackbar(
            "Une erreur est survenue lors de la recherche de tarif",
            {
              variant: "error",
            }
          )
        );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tarifInfo) {
      tarifApi
        .getPremierPrelevement(
          tarifInfo.tarifPropose.proposition.annualTTC,
          new Date(dataSouscription.data!.contractStartDate!)
        )
        .then((premierPrelevement) => {
          const montant =
            premierPrelevement.paymentPlan[0].firstPremiumAmountInclTax +
            montantTaxeAttentat;
          setMontantPremierPaiement(parseFloat(montant.toFixed(2)));
        })
        .catch(() =>
          enqueueSnackbar(
            "Une erreur est survenue lors de la recherche de tarif",
            {
              variant: "error",
            }
          )
        );
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tarifInfo]);

  const creationPolice: () => Promise<string | null> = () =>
    new Promise(async (resolve) => {
      // TODO: Appeler l'api de creation de police
      resolve(null);
    });

  const montantAnnuelAvecTA =
    tarifInfo?.tarifPropose?.proposition?.annualTtcWithSurtaxe;
  const montantTaxeAttentat =
    tarifInfo?.tarifPropose?.proposition?.annualSurtaxe;

  const loading = !(
    montantAnnuelAvecTA &&
    montantTaxeAttentat &&
    montantPremierPaiement
  );

  if (loading) {
    return (
      <>
        <Typography variant="h6">
          {t("pno.recapitulatif.recaptiulatifEtSignature")}
        </Typography>
        <Loader />
      </>
    );
  }

  return (
    <>
      <Typography variant="h6">
        {t("pno.recapitulatif.recaptiulatifEtSignature")}
      </Typography>

      <section className={classes.recapitulatifLayout}>
        <section>
          <InfoClient />
          <InfoBien />
          <InfoTarif
            montantPremierPrelevement={montantPremierPaiement}
            montantTaxeAttentat={montantTaxeAttentat}
          />
          <Confirmations
            montantPremierPrelevement={montantPremierPaiement}
            setIsFormValide={setIsFormValide}
          />
          <BlocTarif
            montantAnnuelAvecTA={montantAnnuelAvecTA}
            montantTaxeAttentat={montantTaxeAttentat}
          />
        </section>
        <BoxRecap
          titre={t("pno.coordonéesSignature")}
          sousTitre1={userState.email || "-"}
          sousTitre2="-"
        />
        <div>
          <BoutonRetour />
          <section>
            <BoutonFinaliserPlusTard />
            <BoutonSuivant
              label={t("pno.recapitulatif.signerParMail")}
              onClick={creationPolice}
              estDesactive={!isFormValide}
            />
          </section>
        </div>
      </section>
    </>
  );
};

export const TextSurbrillance = (
  <span style={{ color: theme.palette.primary.main, fontWeight: "bold" }} />
);
