import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import TableContainer from "@material-ui/core/TableContainer";
import MuiTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import SearchIcon from "@material-ui/icons/Search";

import Popin from "@/components/Popin";
import {
  // FilterPayload,
  FilterPayloadRecord,
  SortDirectionsEnum,
  SortPayload,
} from "@/types/ApiResponse";
import Loading from "@/components/StaticTable/Loading";

import { Filters } from "./Filters";
import { OperatorDataType } from "./Filters/operators";
import useStyle from "./style";

interface Filter {
  key: string; //keyof T;
  rowType: OperatorDataType;
}
export interface DataStructure<T> {
  id?: string;
  renderer: (e: T) => ReactElement | string;
  label?: string;
  filter?: Filter;
  align?: "left" | "right" | "center";
  sort?: string;
}

interface StaticTableProps<T> {
  data: T[];
  dataStructure: DataStructure<T>[];
  identifyRow: (element: T) => T[keyof T];
  renderFooter?: () => ReactElement;
  onValidateFilter?: (newFilter: FilterPayloadRecord) => void;
  onSort?: (newSorting: SortPayload) => void;
  sort?: SortPayload;
  loading: boolean;
}

function StaticTable<T>(props: StaticTableProps<T>) {
  const {
    data,
    dataStructure,
    identifyRow,
    renderFooter,
    onValidateFilter,
    onSort,
    loading,
    sort,
  } = props;

  const { t } = useTranslation();
  const classes = useStyle();

  const [selectedColumnForFilter, setSelectedColumnForFilter] =
    useState<DataStructure<T> | null>(null);
  const [popinOpen, setPopinOpen] = useState(false);

  const handleFilterClick = (column: DataStructure<T>) => {
    setSelectedColumnForFilter(column);
    setPopinOpen(true);
  };

  const handleSortClick = (newSort: string) => {
    let sortFilter;

    if (sort?.id !== newSort) {
      sortFilter = {
        direction: SortDirectionsEnum.ASCENDING,
        id: newSort,
      };
    } else if (sort?.direction === SortDirectionsEnum.ASCENDING) {
      sortFilter = {
        direction: SortDirectionsEnum.DESCENDING,
        id: newSort,
      };
    } else if (sort?.direction === SortDirectionsEnum.DESCENDING) {
      sortFilter = null;
    } else {
      sortFilter = {
        direction: SortDirectionsEnum.ASCENDING,
        id: newSort,
      };
    }
    if (onSort) onSort(sortFilter as SortPayload);
  };

  const getSortIcon = (newSort: string) => {
    if (sort?.id !== newSort) return "fas fa-sort";
    switch (sort?.direction) {
      case sort && SortDirectionsEnum?.ASCENDING:
        return "fas fa-sort-up";
      case sort && SortDirectionsEnum?.DESCENDING:
        return "fas fa-sort-down";
      default:
        return "fas fa-sort";
    }
  };

  const handleValidateFilter = (newFilter: FilterPayloadRecord) => {
    if (onValidateFilter) onValidateFilter(newFilter as FilterPayloadRecord);

    setPopinOpen(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <MuiTable>
          <TableHead>
            <TableRow>
              {dataStructure?.map((column, index) => (
                <TableCell key={index} classes={{ head: classes.headerCell }}>
                  <div
                    className={classes.headerCellWrapper}
                    style={{
                      display: "flex",
                      justifyContent: column.align || "center",
                    }}
                  >
                    <div>{column.label}</div>
                    {column.filter && (
                      <div className={classes.headerCellButtonWrapper}>
                        <SearchIcon onClick={() => handleFilterClick(column)} />
                      </div>
                    )}
                    {column.sort && (
                      <div className={classes.headerCellButtonWrapper}>
                        <Icon
                          className={getSortIcon(column.sort)}
                          onClick={() => handleSortClick(column.sort as string)}
                        />
                      </div>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {loading ? (
            <Loading dataStructure={dataStructure} />
          ) : (
            <TableBody>
              {data && data.length !== 0 ? (
                data.map((item) => (
                  <TableRow
                    key={identifyRow(item) as string}
                    classes={{ root: classes.tableRow }}
                  >
                    {dataStructure?.map((column, index) => (
                      <TableCell
                        key={index}
                        classes={{ root: classes.cell }}
                        align={column?.align || "center"}
                      >
                        {column.renderer(item) || "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow classes={{ root: classes.tableRow }}>
                  <TableCell
                    classes={{ root: classes.cell }}
                    align={"center"}
                    colSpan={dataStructure.length}
                  >
                    {t("common.dmri.paginate.noData")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}

          {!loading && renderFooter && data.length !== 0 && (
            <TableFooter>
              <TableRow>{renderFooter()}</TableRow>
            </TableFooter>
          )}
        </MuiTable>
      </TableContainer>
      <Popin
        title={t("common.dmri.filter.title")}
        open={popinOpen}
        content={
          <Filters<T>
            dataStructure={dataStructure}
            onValidate={handleValidateFilter}
            defaultSelectedcolumn={selectedColumnForFilter as DataStructure<T>}
          />
        }
        onClose={() => setPopinOpen(false)}
        maxWidth="md"
        showActionsButtons={false}
      />
    </>
  );
}

export default StaticTable;
