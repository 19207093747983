import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from '@material-ui/core/Typography';

import useStyles from './style';
import PropTypes from 'prop-types';

const Status = ({ police }) => {
  const MISE_EN_DEMEURE = 'SUNPP';
  const PREFIXE_RESILIATION = 'RE';

  const estContraMisEnDemeure = (police) =>
    police.status.code === MISE_EN_DEMEURE;
  const estContratResilie = (police) =>
    police.status.code.startsWith(PREFIXE_RESILIATION);

  const classes = useStyles({ erreur: estContraMisEnDemeure(police) });
  const [policyStatus, setPolicyStatus] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (police) {
      if (estContraMisEnDemeure(police)) {
        setPolicyStatus(t('dashboard.dmri.contract.statuts.sunpp'));
      } else if (estContratResilie(police)) {
        setPolicyStatus(t('dashboard.dmri.contract.statuts.re'));
      }
    }
  }, [police, t]);

  if (policyStatus === null) return <></>;

  return (
    <div className={`${classes.vignetteWrapper}`}>
      <div>
        <ErrorOutlineIcon />
        <Typography variant="body2">{policyStatus}</Typography>
      </div>
    </div>
  );
};

Status.propTypes = {
  police: PropTypes.object.isRequired,
};

export default Status;
