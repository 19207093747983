import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import PnoSvg from "assets/images/contratSignee.svg";

import { BoxRecap } from "@/views/PnoSouscription/BoxRecap";
import { UserContext } from "@/contexts/user";

export const Signature = () => {
  // @ts-ignore
  const [userState] = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6">{t("pno.titrePageSignature")}</Typography>

      <section style={{ display: "flex", alignItems: "center" }}>
        <img src={PnoSvg} alt="Contrat PNO" />
        <Typography style={{ marginLeft: "20px" }}>
          {t("pno.confirmationEnvoiEmailSignature")}
        </Typography>
        <BoxRecap
          titre={t("pno.coordonéesSignature")}
          sousTitre1={userState.email || "-"}
          sousTitre2="-"
        />
      </section>
    </>
  );
};
