import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: theme.palette.blue.light,
    justifyContent: 'space-between',
    // height: 30,

    '& .boxClose': {
      display: 'flex',
      color: theme.palette.white.main,
      cursor: 'pointer'
    }
  },
  title: {
    fontSize: 17,
    fontWeight: 'bolder',
    alignSelf: 'flex-end',
    color: theme.palette.white.main
  },
  content: {
    padding: 0,
  },
  icon: { width: 'auto', marginRight: 10, color: theme.palette.primary.main },
  footerWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    //backgroundColor: theme.palette.blue.light
    // height: 30,
  },
  
}));

export default useStyles;
