import axiosClient from "@/api/axiosClient";

const BASE_URL_V3 = "/v3/devis";

interface DevisPnoFonciaParams {
  parcoursUUID: string;
}

const devisApi = {
  genererDevisC2: async ({ parcoursUUID }: DevisPnoFonciaParams) => {
    const url = `${BASE_URL_V3}/${parcoursUUID}/telecharger`;
    const response: BlobPart = await axiosClient.post(
      url,
      { devisPourC2: true },
      {
        responseType: "arraybuffer",
      }
    );

    return response;
  },
};

export default devisApi;
