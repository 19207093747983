import React, {
  createContext,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";

import { Souscription } from "@/types/Souscription";
import { Header } from "./Header";
import { InfosPerso } from "./Etapes/InfosPerso";
import { Adresse } from "./Etapes/Adresse";
import { TypeBien } from "./Etapes/TypeBien";
import { DateEffet } from "./Etapes/DateEffet";
import { Recapitulatif } from "./Etapes/Recapitulatif";
import { Signature } from "./Etapes/Signature";
import { useStyles } from "./style";
import souscriptionApi from "@/api/souscriptionApi";
import Loader from "@/components/Loader";
import { URLS } from "@/constants/urls";

export const EtapeObj = {
  /** ATTETION, L'ORDRE DE L'OBJET DEFINIT L'ORDRE DES ECRANS DE SOUSCRIPTION */

  INFOS_PERSO: <InfosPerso />,
  ADRESSE: <Adresse />,
  TYPE_DU_BIEN: <TypeBien />,
  DATE_EFFET: <DateEffet />,
  RECAPITULATIF: <Recapitulatif />,
  SIGNATURE: <Signature />,
} as const;

export type Etape = keyof typeof EtapeObj;
const etapeList: Etape[] = Object.keys(EtapeObj) as Etape[];

export const PnoSouscriptionContexte = createContext<{
  dataSouscription: Partial<Souscription>;
  setDataSouscription: (data: Partial<Souscription>) => void;
  retourEtapePrecedente: () => void;
  parcoursUUID: string | null;
  formRef: React.RefObject<HTMLFormElement> | null;
}>({
  dataSouscription: {},
  setDataSouscription: () => null,
  retourEtapePrecedente: () => null,
  parcoursUUID: null,
  formRef: null,
});

const PnoSouscription = () => {
  const { id } = useParams();
  const redirect = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const parcoursUUID = useRef<string | null>(id || null);
  const formRef = useRef<HTMLFormElement>(null);
  const [chargementParcoursExistant, setChargementParcoursExistant] =
    useState<boolean>(!!id);
  const [etape, setEtape] = useState<Etape>(etapeList[0]);
  const [dataSouscription, setDataSouscription] = useState<
    Partial<Souscription>
  >({ skipSteps: false, visitedSteps: [] });

  useEffect(() => {
    const recupereInformationsSouscriptionPNO = async () => {
      if (id) {
        try {
          let result =
            (await souscriptionApi.recupereInformationsSouscriptionPNO(
              id
            )) as unknown as Souscription;

          setDataSouscription(result);
          const etapeResult = result.currentStep.step;
          if (etapeResult && etapeResult in EtapeObj) {
            setEtape(etapeResult as Etape);
          } else setEtape(etapeList[0]);
        } catch (error) {
          showErrorSnackbar(
            "Erreur lors de la récupération des informations du parcours"
          );
          redirect(URLS.PNO_SOUSCRIPTION);
        } finally {
          setChargementParcoursExistant(false);
        }
      }
    };

    recupereInformationsSouscriptionPNO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enregistreParcoursSouscription = (newData: Partial<Souscription>) =>
    souscriptionApi
      .persisteInformationsSouscriptionPNO(newData, parcoursUUID.current)
      .then((response) => {
        // @ts-ignore
        const newParcoursUUID = response.parcoursUuid;
        redirect(URLS.PNO_SOUSCRIPTION + "/" + newParcoursUUID);
        parcoursUUID.current = newParcoursUUID;
      })
      .catch(() => {
        showErrorSnackbar(
          "Un erreur s'est produite lors de l'enregistrement du parcours"
        );
      });

  const showErrorSnackbar = (message: string) => {
    enqueueSnackbar(message, { variant: "error" });
  };

  const valideEtapeCourante = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const indexEtapeCourante = etapeList.indexOf(etape);
    const etapeSuivante = etapeList[indexEtapeCourante + 1];

    setEtape(etapeSuivante);

    const visitedStepsActuel = dataSouscription.visitedSteps || [];
    const visitedSteps = [...visitedStepsActuel, etape];
    const newData = {
      ...dataSouscription,
      visitedSteps: visitedSteps,
      currentStep: {
        step: etapeSuivante,
      },
      data: {
        ...dataSouscription.data!,
        from: "Espace PRO",
      },
    };

    setDataSouscription(newData);

    if (
      dataSouscription.data?.contact &&
      dataSouscription.data.contractAddress
    ) {
      enregistreParcoursSouscription(newData);
    }
  };

  const retourEtapePrecedente = () => {
    var indexEtapeCourante = etapeList.indexOf(etape);
    setEtape(etapeList[indexEtapeCourante - 1]);
  };

  return (
    <PnoSouscriptionContexte.Provider
      value={{
        dataSouscription,
        setDataSouscription,
        retourEtapePrecedente,
        parcoursUUID: parcoursUUID.current,
        formRef,
      }}
    >
      <Header />
      {chargementParcoursExistant ? (
        <Loader />
      ) : (
        <form
          ref={formRef}
          onSubmit={valideEtapeCourante}
          className={classes.layout}
        >
          {EtapeObj[etape]}
        </form>
      )}
    </PnoSouscriptionContexte.Provider>
  );
};

export default PnoSouscription;
