import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  subItem: {
    paddingTop: 1,
    paddingBottom: 2,
    paddingLeft: theme.spacing(4),
  },
  icon: {
    fontSize: 14,
    width: 14,
    marginRight: 12
  },
  text: {
    fontSize: 14,
  },
}));

export default useStyles;
