import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@/components/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import useStyles from "./style";

const Info = (props) => {
  const [open, setOpen] = React.useState(false);
  const { title } = props;
  const classes = useStyles();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <InputAdornment position="end">
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            arrow
            placement="top"
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={title}
          >
            <IconButton
              onClick={handleTooltipOpen}
              classes={{ root: classes.infoButton }}
            >
              <Icon
                className="fas fa-info-circle"
                classes={{ root: classes.infoIcon }}
              />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </InputAdornment>
  );
};

Info.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Info;
