import axiosClient from "@/api/axiosClient";
import moment from "moment";

const BASE_URL = "v2/planned-payments";

const planifiedPaymentsApi = {
  async getPlanifiedPayments(auth) {
    const currentDate = new Date();
    const minPlanifiedDate = moment(currentDate)
      .add(-13, "month")
      .format("YYYY-MM-DDTHH:mm:ss[Z]")
      .toString();
    const maxPlanifiedDate = moment(currentDate)
      .add(1, "month")
      .format("YYYY-MM-DDTHH:mm:ss[Z]")
      .toString();
    const type = "PRELEV_IMM_FONCIA";

    const url = `${BASE_URL}/?type=${type}&minPlanifiedDate=${minPlanifiedDate}&maxPlanifiedDate=${maxPlanifiedDate}`;

    const response = await axiosClient.get(url);

    return response;
  },
  async exportPaymentPlanned(auth, paymentPlannedUuid, officeCode) {
    let url = `${BASE_URL}/${paymentPlannedUuid}/office/${officeCode}`;

    if (["8003", "8014", "0503"].includes(officeCode)) {
      url = `${BASE_URL}/${paymentPlannedUuid}/office/0140`;
    }

    const response = await axiosClient.get(url);
    return response;
  },
};

export default planifiedPaymentsApi;
