import axiosClient from "@/api/axiosClient";
import { Pays } from "@/types/pays";

const BASE_URL = "/v2/drs";

const drsApi = {
  async rechercherListPays() {
    const url = `${BASE_URL}/PAYS_FINSCAN`;

    const reponse = await axiosClient.get<Pays[]>(url);

    //@ts-ignore
    return reponse as Pays[];
  },
};

export default drsApi;
