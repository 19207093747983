import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import policyApi from "@/api/policyApi";
import { formatDate, formatPrice } from "@/utils";
import { getTypeLabel, getEtatLabel, getTypePaiement } from "./helper";
import { VisualisationPDF } from "./VisualisationPDF";
import {
  EtatQuittance,
  EtatQuittanceEnum,
  QuittanceLight,
  TypePaiementQuittance,
  TypeQuittanceEnum,
} from "@/types/Quittance";
import { CodeLabel } from "@/types/CodeLabel";
import { ApiTable } from "@/components/ApiTable";

const Premiums: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <ApiTable<QuittanceLight>
      rowIdentifier="premiumNumber"
      getData={() => policyApi.getPolicePremiums(id as string)}
      dataStructure={[
        {
          renderer: (e) => e.premiumNumber.toString(),
          label: t("dashboard.dmri.quittances.numeroQuittance"),
        },
        {
          renderer: (e) => getTypeLabel(e.type),
          label: t("dashboard.dmri.quittances.type"),
        },
        {
          renderer: (e) => afficherEtat(e.state.code),
          label: t("dashboard.dmri.quittances.etat"),
        },
        {
          renderer: (e) => afficherMontant(e, t),
          label: t("dashboard.dmri.quittances.ttc"),
        },
        {
          renderer: (e) => formatDate(e.startDate, "DD/MM/YYYY"),
          label: t("dashboard.dmri.quittances.dateEffet"),
        },
        {
          renderer: (e) => formatDate(e.endDate, "DD/MM/YYYY"),
          label: t("dashboard.dmri.quittances.dateFinEffet"),
        },
        {
          renderer: (e) => afficherTypePaiement(e.expectedPaimentType),
          label: t("dashboard.dmri.quittances.typePaiement"),
        },
        {
          renderer: (e) => <VisualisationPDF quittance={e} />,
        },
      ]}
    />
  );
};

const afficherEtat = (etat: EtatQuittance) => {
  if (etat === EtatQuittanceEnum.MISE_EN_DEMEURE)
    return <Typography color={"error"}>{getEtatLabel(etat)}</Typography>;
  return getEtatLabel(etat);
};

const afficherMontant = (
  quittance: QuittanceLight,
  t: (code: string) => string
) => {
  if (quittance.type === TypeQuittanceEnum.ACCOMPTE && quittance.accompte !== 0)
    return (
      <>
        {formatPrice(quittance.amount)}
        <br />
        {t("dashboard.dmri.quittances.types.AC")} :{" "}
        {formatPrice(quittance.accompte)}
      </>
    );

  return formatPrice(quittance.amount);
};

const afficherTypePaiement = (typePaiement: CodeLabel<TypePaiementQuittance>) =>
  typePaiement && (
    <Chip label={getTypePaiement(typePaiement.code)} variant="outlined" />
  );

export default Premiums;
