import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  dateEffetLayout: {
    "& > section:first-of-type": {
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      paddingRight: 25,
      "& > p": {
        marginRight: theme.spacing(4),
      },
      "& > div": {
        minWidth: theme.spacing(28),
      },
    },
    "& > div:last-of-type": {
      padding: 10,
      borderRadius: 10,
      backgroundColor: "#FF9100",
      color: "white",
      marginLeft: "auto",
      "& > p": {
        color: "white",
        fontSize: 16,
      },
    },
  },
}));
