import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chipWip: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  chip: {
    borderColor: theme.palette.green.main,
    color: theme.palette.green.main,
  },
}));

export default useStyles;
