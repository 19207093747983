interface Page {
  pageSize: number;
  pageNumber: number;
}
export interface PageableReponse<T> {
  pageable: Page;
  totalElements: number;
  content: T[];
}

export interface FilterPayload {
  value: string;
  operateur: string;
  valueIn: string;
  valueMin?: number;
  valueMax?: number;
}

export interface SortPayload {
  id: string;
  direction: SortDirections;
}

export type FilterPayloadRecord = Record<string, FilterPayload>;

export interface PayloadWithPageAndFilter {
  filter: FilterPayloadRecord;
  sort: SortPayload;
  page: number;
  size: number;
}

export enum SortDirectionsEnum {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export type SortDirections = SortDirectionsEnum;