import React, { useContext } from "react";
import { useTranslation, Trans } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import { Souscription } from "@/types/Souscription";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import { TextSurbrillance } from "@/views/PnoSouscription/Etapes/Recapitulatif";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type Adresse = getTypeOriginalDepuisPartial<
  Souscription["data"]["contractAddress"]
>;

const PNO_TYPE_MAISON = "M";
const PNO_TYPE_APPARTEMENT = "A";
const PNO_TYPE_GARAGE = "BG";
const PNO_TYPE_LOCAL_COMMERCIAL = "LC";

export const InfoBien = () => {
  const { dataSouscription } = useContext(PnoSouscriptionContexte);
  const { t } = useTranslation();
  const data = dataSouscription.data?.contact;

  const adresse: Partial<Adresse> =
    dataSouscription.data?.contractAddress || {};
  const typeBien = dataSouscription.data?.housingType;
  const activite = dataSouscription.data?.typeActivite;
  const paysNaissance = data?.countryBirth || "-";

  const meuble = dataSouscription.data?.furnished
    ? t("pno.meuble")
    : t("pno.nonMeuble");
  const surface = dataSouscription.data?.area;
  const nombreDePieces = dataSouscription.data?.numberOfPrincipalRooms;
  const adresse1 = adresse.address1 || "-";
  const adresse2 = adresse.address2 || "";
  const ville = adresse.city || "-";
  const zipCode = adresse.zipCode || "-";

  return (
    <div style={{ width: "70%" }}>
      <Typography>{t("pno.recapitulatif.bien.intro")}</Typography>
      {typeBien === PNO_TYPE_MAISON ? (
        <Typography>
          <Trans
            i18nKey="pno.recapitulatif.bien.infoMaison"
            values={{
              meuble,
              surface,
              paysNaissance,
              nombreDePieces,
              adresse1,
              adresse2,
              ville,
              zipCode,
            }}
            components={{ style: TextSurbrillance }}
          />
        </Typography>
      ) : typeBien === PNO_TYPE_APPARTEMENT ? (
        <Typography>
          <Trans
            i18nKey="pno.recapitulatif.bien.infoAppartement"
            values={{
              meuble,
              surface,
              paysNaissance,
              nombreDePieces,
              adresse1,
              adresse2,
              ville,
              zipCode,
            }}
            components={{ style: TextSurbrillance }}
          />
        </Typography>
      ) : typeBien === PNO_TYPE_LOCAL_COMMERCIAL ? (
        <Typography>
          <Trans
            i18nKey="pno.recapitulatif.bien.infoLocalCommercial"
            values={{ surface, activite, adresse1, adresse2, ville, zipCode }}
            components={{ style: TextSurbrillance }}
          />
        </Typography>
      ) : typeBien === PNO_TYPE_GARAGE ? (
        <Typography>
          <Trans
            i18nKey="pno.recapitulatif.bien.infoGarage"
            values={{ surface, adresse1, adresse2, ville, zipCode }}
            components={{ style: TextSurbrillance }}
          />
        </Typography>
      ) : (
        <Typography>-</Typography>
      )}
    </div>
  );
};
