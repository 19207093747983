import { PoliceLight } from './Police';
import { CodeLabel } from './CodeLabel';

export type QuittanceLight = {
  premiumNumber: number;
  premiumUuid: string;
  policy: PoliceLight;
  amount: number;
  startDate: Date;
  endDate: Date;
  state: CodeLabel<EtatQuittance>;
  expectedPaimentType: CodeLabel<TypePaiementQuittance>;
  type: TypeQuittance;
  accompte: number;
};

export enum TypeQuittanceEnum {
  COMPTANT = 'QCO',
  RISTOURNE = 'RIS',
  TERME = 'TER',
  ACCOMPTE = 'AC',
  REGLEMENT = 'RE',
  RISTOURNE_NON_CERTIFIE = 'RNC',
}
export type TypeQuittance = TypeQuittanceEnum | null;

export enum EtatQuittanceEnum {
  REGLEE = 'REGLEE',
  SUPPRIMEE = 'SUPPRIMEE',
  A_REGLER = 'A_REGLER',
  PREAVIS_MISE_EN_DEMEURE = 'PREAVIS_MISE_EN_DEMEURE',
  MISE_EN_DEMEURE = 'MISE_EN_DEMEURE',
  EN_COURS = 'EN_COURS',
  COMPTE_BANCAIRE = 'COMPTE_BANCAIRE',
  PRELEVEMENT = 'PRELEVEMENT',
}
export type EtatQuittance = EtatQuittanceEnum | null;

export enum TypePaiementQuittanceEnum {
  CHEQUES = 'CH',
  CARTE_BANCAIRE = 'CB',
  PAIEMENT_AVEC_ENREGISTREMENT_CARTE_BANCAIRE = 'ENROLEMENT_CB',
  PRELEVEMENT_CARTE_BANCAIRE = 'PRELEVEMENT_CB',
}
export type TypePaiementQuittance = TypePaiementQuittanceEnum | null;
