import React from "react";

import Lottie from "@/components/Lottie";
import LoaderLottie from "@/assets/lotties/loader.json";

import useStyles from "./style";

const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.loader}>
      <Lottie animationData={LoaderLottie} isActive autoPlay height={150} />
    </div>
  );
};

export default Loader;
