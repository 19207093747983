import dashboard from "./dashboard.json";
import common from "./common.json";
import pno from "./pno.json";

const en = {
  translation: {
    dashboard,
    common,
    pno,
  },
};

export default en;
