import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import TextField from "@/components/TextField/index";
import OPERATORS from "./operators";
import SelectField from "../SelectField";

const Filters = (props) => {
  const { defaultSelectedcolumn, dataStructure, filterType, onValidate } =
    props;
  const { t } = useTranslation();

  const [selectedColumn, setSelectedColumn] = useState(defaultSelectedcolumn);
  const [selectedOperator, setSelectedOperator] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const [filterValueMin, setFilterValueMin] = useState(null);
  const [filterValueMax, setFilterValueMax] = useState(null);

  const [hasValue, setHasValue] = useState(null);
  const [isValidateButtonDisabled, setIsValidateButtonDisabled] =
    useState(true);
  const [placeHolderText, setPlaceHolderText] = useState(
    t("common.dmri.filter.placeHolderValue")
  );
  const [selectedFilter, setSelectedFilter] = useState(null);

  const getOperators = () => {
    return OPERATORS.filter((operator) => {
      if (!selectedColumn) return false;
      if (operator.type.includes(selectedColumn.filter.rowType)) return true;
      return false;
    });
  };

  const handleSelectedOperatorChange = (event) => {
    if (event.target.value === "IN")
      setPlaceHolderText(t("common.dmri.filter.placeHolderValueIn"));
    setSelectedOperator(event.target.value);
  };

  const handleFilterValueChange = (event, updateFunc) => {
    updateFunc(event.target.value);
  };

  const handleFilterValidation = () => {
    const operatorIn = selectedOperator === "IN";
    let valueIn = null;

    if (operatorIn) {
      valueIn = filterValue.split(",").map((v) => v.trim());
      if (selectedColumn.filter.rowType === "number") {
        valueIn = valueIn.map((v) => +v);
      }
    }

    const newFilter = {
      [selectedFilter.filter.id]: {
        value: filterValue,
        operateur: selectedOperator.value,
        valueIn: valueIn,
        ...(selectedColumn.type === "number" && {
          valueMin: filterValueMin,
          valueMax: filterValueMax,
        }),
      },
    };

    onValidate(newFilter);
  };

  const isBetweenOperatorSelected = () => {
    return selectedOperator?.value === "BETWEEN" || false;
  };

  useEffect(() => {
    setHasValue(filterValue || (filterValueMin && filterValueMax));
  }, [filterValue, filterValueMin, filterValueMax]);

  useEffect(() => {
    setIsValidateButtonDisabled(
      !(selectedColumn && selectedOperator && hasValue)
    );
  }, [selectedColumn, selectedOperator, hasValue]);

  useEffect(() => {
    if (filterType && selectedColumn) setSelectedFilter(selectedColumn);
  }, [filterType, selectedColumn]);

  return (
    <Box m={2}>
      <Grid container>
        <Grid container item spacing={3}>
          <Grid item md={3}>
            <SelectField
              id="selectedColumn"
              label={t("common.dmri.filter.field")}
              items={dataStructure.filter(
                (column) => column.filter?.type === filterType
              )}
              size="dense"
              onChange={(e) => setSelectedColumn(e.target.value)}
              value={selectedColumn}
            />
          </Grid>
          <Grid item md={3}>
            <SelectField
              id="selectedOperator"
              label={t("common.dmri.filter.operator")}
              items={getOperators()}
              onChange={handleSelectedOperatorChange}
              value={selectedOperator}
            />
          </Grid>
          {isBetweenOperatorSelected() ? (
            <>
              <Grid item md={3}>
                <TextField
                  label={t("common.dmri.filter.valueMin")}
                  onChange={(e) =>
                    handleFilterValueChange(e, setFilterValueMin)
                  }
                  value={filterValueMin}
                  placeholder={t("common.dmri.filter.placeHolderValueMin")}
                  fullWidth
                />
              </Grid>
              <Grid item md={3}>
                <TextField
                  label={t("common.dmri.filter.valueMax")}
                  onChange={(e) =>
                    handleFilterValueChange(e, setFilterValueMax)
                  }
                  value={filterValueMax}
                  placeholder={t("common.dmri.filter.placeHolderValueMax")}
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <Grid item md={6}>
              <TextField
                label={t("common.dmri.filter.value")}
                onChange={(e) => handleFilterValueChange(e, setFilterValue)}
                value={filterValue}
                placeholder={placeHolderText}
                fullWidth
              />
            </Grid>
          )}
        </Grid>
        <Grid item md={12}>
          <Box mt={3} display="flex" justifyContent="end">
            <Button
              onClick={handleFilterValidation}
              color="primary"
              variant="contained"
              disabled={isValidateButtonDisabled}
            >
              {t("common.dmri.filter.btnApply")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

Filters.propTypes = {
  defaultSelectedcolumn: PropTypes.object.isRequired,
  dataStructure: PropTypes.array.isRequired,
  filterType: PropTypes.string.isRequired,
  onValidate: PropTypes.func.isRequired,
};

export default Filters;
