import axiosClient from "@/api/axiosClient";

import { QuittanceLight } from '@/types/Quittance';
import { Police } from '@/types/Police';
import { PageableReponse, PayloadWithPageAndFilter } from '@/types/ApiResponse';

const BASE_URL_V2 = "/v2/policies";
const BASE_URL_V3 = "/v3/policies";

const getPolice = async (policyNumber: string) => {
  const url = `${BASE_URL_V2}/${policyNumber}`;

  const response = await axiosClient.get(url);
  return response;
};

const getMriRiskByPolice = async (policyNumber: string) => {
  const url = `${BASE_URL_V3}/${policyNumber}/risk`;

  const response = await axiosClient.get(url);
  return response;
};

const getPoliciesByOfficeCode = async (
  auth: any,
  societeRattachement: string,
  types: string,
  period: string
) => {
  // let url = `${BASE_URL_V2}/office/${societeRattachement}?types=${types}&period=${period}`;
  let url = `${BASE_URL_V2}/office/0140?types=${types}&period=${period}`;

  if (societeRattachement === "8003") {
    //michael Prod et recette
    url = `${BASE_URL_V2}/office/0140?types=${types}&period=${period}`; //0675
  } else if (societeRattachement === "8014") {
    //Guillaume Prod
    url = `${BASE_URL_V2}/office/0140?types=${types}&period=${period}`;
  } else if (societeRattachement === "0503") {
    //Guillaume Recette
    url = `${BASE_URL_V2}/office/0140?types=${types}&period=${period}`;
  }

  try {
    const response = await axiosClient.get(url);
    return response;
  } catch {
    return [];
  }
};

const getPoliciesByOfficeCodeAndPlanifiedPayment = async (
  auth: any,
  societeRattachement: string,
  planifiedPaymentUuid: string
) => {
  console.log("société rattachement", societeRattachement);
  // planifiedPaymentUuid = '1a7e1091-37cb-423a-9966-603b83fdd8b0';
  let url = `${BASE_URL_V2}/paymentPlannified/office/${societeRattachement}?uuid=${planifiedPaymentUuid}`;
  // url = `${BASE_URL}/paymentPlannified/office/0290?uuid=${planifiedPaymentUuid}`;

  if (societeRattachement === "8003") {
    url = `${BASE_URL_V2}/paymentPlannified/office/0140?uuid=${planifiedPaymentUuid}`;
  } else if (societeRattachement === "8014") {
    url = `${BASE_URL_V2}/paymentPlannified/office/0140?uuid=${planifiedPaymentUuid}`;
  } else if (societeRattachement === "0503") {
    url = `${BASE_URL_V2}/paymentPlannified/office/0140?uuid=${planifiedPaymentUuid}`;
  }

  try {
    const response = await axiosClient.get(url);

    return response;
  } catch (e) {
    return [];
  }
};

const changePaymentMode = async (
  auth: any,
  societeRattachement: string,
  policyNumber: string,
  paymentMode: string,
  comment: string,
  paymentPlannedUuid: string
) => {
  const url = `${BASE_URL_V2}/${policyNumber}/change-payment-mode`;

  let officeCode = societeRattachement;
  if (["8003", "8014", "0503"].includes(societeRattachement)) {
    officeCode = "0140";
  }

  const response = await axiosClient.put(
    url,
    {
      // @ts-ignore
      modePayment: paymentMode,
      paymentPlannedUuid,
      // groupment: '2021-06',
      reasonRefusal: comment,
    },
    {
      headers: {
        "office-code": officeCode,
        // 'office-code': '0290',
      },
    }
  );

  return response;
};

const getContratsMri = async (params: PayloadWithPageAndFilter) => {
  const url = `${BASE_URL_V2}/MRI`;

  const response = await axiosClient.post<PageableReponse<Police>>(url, params);
  return response;
};

const getContratsPno = async (auth: any, params: PayloadWithPageAndFilter) => {
  const url = `${BASE_URL_V2}/DNO`;

  const response = await axiosClient.post<PageableReponse<Police>>(url, params);
  return response;
};

const getPoliceClaims = async (policyNumber: string) => {
  const url = `${BASE_URL_V2}/${policyNumber}/claims`;

  const response = await axiosClient.get(url);

  return response;
};

const getPolicePremiums = async (policyNumber: string) => {
  const url = `${BASE_URL_V3}/${policyNumber}/premiums`;

  const response = await axiosClient.get<QuittanceLight[]>(url);

  return response;
};

const policyApi = {
  getPolice,
  getMriRiskByPolice,
  getPoliciesByOfficeCode,
  getPoliciesByOfficeCodeAndPlanifiedPayment,
  changePaymentMode,
  getPoliceClaims,
  getContratsMri,
  getContratsPno,
  getPolicePremiums,
};

export default policyApi;
