import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import { URLS } from "@/constants/urls";
import { Breadcrumb } from "./Breadcrumb";

export const FilAriane = () => {
  const { t } = useTranslation();
  const param = useParams();
  const location = useLocation();

  const filArianeObj: Record<URLS, { titre: string; desc?: string }> = {
    [URLS.BOARD]: {
      titre: t("dashboard.menu.dashboard"),
    },
    [URLS.MRI_DMRI]: {
      titre: t("common.dmri.multiriskBuildingInsurance"),
    },
    [URLS.MRI_DMRI_ID]: {
      titre: t("common.dmri.multiriskBuildingInsurance"),
      desc: t("common.dmri.contractDetail") + " " + param.id,
    },
    [URLS.MRI_QUOTE]: {
      titre: t("common.dmri.multiriskBuildingInsurance"),
    },
    [URLS.PNO]: {
      titre: "",
    },
    [URLS.PNO_CONTRACTS]: {
      titre: "",
    },
    [URLS.PNO_SOUSCRIPTION]: {
      titre: t("dashboard.menu.newContract"),
      desc: t("dashboard.menu.souscriptionCompte2"),
    },
    [URLS.PNO_SOUSCRIPTION_REPRISE]: {
      titre: "",
    },
    [URLS.PNO_SOUSCRIPTIONS_EN_COURS]: {
      titre: "",
    },
    [URLS.PNO_CLAIMS]: {
      titre: t("dashboard.menu.ongoingClaims"),
    },
    [URLS.PNO_PREMIUMS]: {
      titre: t("dashboard.menu.ongoingPremiums"),
    },
    [URLS.HOME_INSURANCE]: {
      titre: t("dashboard.menu.homeInsurancePolicy"),
    },
    [URLS.PJ]: {
      titre: t("dashboard.menu.pjContracts"),
    },
    [URLS.DO]: {
      titre: t("dashboard.menu.doContracts"),
    },
    [URLS.TERM_POLICIES]: {
      titre: t("dashboard.menu.plannedPayments"),
    },
    [URLS.CLAIMS]: {
      titre: t("dashboard.menu.claims"),
    },
    [URLS.FORBIDDEN]: {
      titre: "",
    },
    [URLS.MRI]: {
      titre: "",
    },

    [URLS.CONTRATS]: {
      titre: "",
    },
  };

  const url = findUrl(location.pathname);

  if (!url) return null;

  return (
    <Breadcrumb
      title={filArianeObj[url].titre}
      description={filArianeObj[url].desc}
    />
  );
};

const findUrl = (pathname: string): URLS | null => {
  let searchedUrl = pathname.replace("/:id", "");

  const estUneUrlConnue = Object.values(URLS).includes(searchedUrl as URLS);
  if (estUneUrlConnue) return searchedUrl as URLS;

  return null;
};
