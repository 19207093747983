const hasAccess = (neededRights: string[] | string, rights: string[]): boolean => {

  if (typeof neededRights === "string") return rights.includes(neededRights);
  else if (Array.isArray(neededRights))
    return neededRights.reduce((response, right) => {
      if (response === true) return response;
      else return rights.includes(right);
    }, false);
  else return false;
};

export {
  hasAccess,
};
