import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    backgroundColor: theme.palette.blue.light,
    padding: 20,
    gap: 20,
  },
  headerTypography: {
    color: theme.palette.white.main,
  },
  lottieWrapper: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: theme.palette.white.main,
  },
  line: {
    display: 'flex',
    gap: 20,
    padding: 20,
    backgroundColor: theme.palette.white.main,
  },
  lineWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 20,
    backgroundColor: theme.palette.white.main,
  },
  info: {
    color: theme.palette.blue,
  },
  title: {
    color: theme.palette.secondary.main,
  },
  separator: {
    border: `0.5px solid ${theme.palette.grey.superLight}`,
  },
}));

export default useStyles;
